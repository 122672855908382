import React, {useState,useContext } from 'react';
import styled from 'styled-components';
import BeautyStars from 'beauty-stars';
import AudioPlayerContext from '../audioplayer/AudioPlayerContext';
import { FirebaseContext } from '../Firebase';
import { Button } from '@common';
import Textarea from '@form/Textarea';
import Input from '@form/Input';
import { useTranslation } from 'react-i18next'
import { usePageContext }  from '@hooks/usePageContext'


//TODO: each user shoudl only be able to rate a story once
const RatingsCenterHelper = styled.div`
  position: fixed; 
  ${props => props.visible ? 'display:flex;' : 'display:none;'};
  top:0px;
  left: 0px;
  width:100vw;
  height:100vh;
  align-items:center;
  justify-content:center;
  background:#00000087;
  z-index: 999999;
`

const RatingsWrapper = styled.div`
  max-width: min(90vw,400px);
  height: fit-content;
  background-color: white;
  padding: 10px 25px 20px 25px;
  border-radius:20px;
  box-shadow: -12px 4px 61px -16px rgba(0,0,0,0.41);
`

const RatingForm = styled.form`
  padding: 10px 0px;
  display:flex;
  flex-direction:column;
  ${Input}{
    margin-right:8px;
    margin-top:auto;
    margin-bottom:auto;
  }
  ${Button}{
    
  }
`

const CustomText = styled.p`
  text-align:${props => props.center ? 'center;' : ''};
  font-family: ${props => props.theme.mainFontParagraph};
  line-height: 2rem;
  margin:0px;
  `

const StarRatingWrapper = styled.div`
  margin: 20px 0px;
`

const MainHeadingCustom = styled.h2`
  font-size:1.8em;
  text-align:${props => props.center ? 'center;' : ''};
  font-family: ${props => props.theme.mainFontHeading};
  line-height:1.3em;
`

const ErrorMessage = styled.div`
  font-family: ${props => props.theme.mainFontParagraph};
  color:red;
  font-size:0.8rem;
  text-align:center;
`


export const StoryRatings = () => {
  const [ratingStars, setRatingStars] = useState({ value: 0 });
  const [ratingStarsVoice, setRatingStarsVoice] = useState({ value: 0 });
  const [ratingFeedback, setRatingFeedback] = useState('');
  const [didRate, setDidRate] = useState('');
  const {currentStory, setCurrentStory} = useContext(AudioPlayerContext);
  const {firebase} = useContext(FirebaseContext);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation()
  const { lang } =  usePageContext()


  function handlePostRatingSubmit(e){
    e.preventDefault();
    // check if user did interact
    if(didRate.voice && didRate.story){
      firebase.postRating({
        feedback: ratingFeedback,
        storyId: currentStory.id,
        stars: ratingStars.value,
        starsVoice: ratingStarsVoice.value,
        lang:lang
      });
      setCurrentStory({ ...currentStory, showRatingForm: false });
      setRatingStarsVoice({ value: 0 });
      setRatingStars({ value: 0 });
      setRatingFeedback('')
      setErrorMessage('');
    } else {
      setErrorMessage(t('story-ratings.flashMessage-1'));
    }
  }

  function handleStarRating(value){
    setRatingStars(value);
    setDidRate({...didRate,story:true})
  }

  function handleStarRatingVoice(value){
    setRatingStarsVoice(value);
    setDidRate({...didRate,voice:true})
  }
  
  function closeRating(){
    setCurrentStory({ ...currentStory, showRatingForm: false });
    setRatingStarsVoice({ value: 0 });
    setRatingStars({ value: 0 });
    setRatingFeedback('')
    setErrorMessage('');
  }

  return(
    <RatingsCenterHelper visible={currentStory.showRatingForm}>
      <RatingsWrapper>
        <MainHeadingCustom center>{t('story-ratings.sectionTitle-1')}</MainHeadingCustom>
        <StarRatingWrapper>
          <CustomText>{t('story-ratings.sectionText-1')}</CustomText>
          <BeautyStars
            size="34"
            value={ratingStars.value}
            onChange={value => handleStarRating({ value })}
          />
        </StarRatingWrapper>
        <StarRatingWrapper>
          <CustomText>{t('story-ratings.sectionText-2')}</CustomText>
          <BeautyStars
            size="34"
            value={ratingStarsVoice.value}
            onChange={value => handleStarRatingVoice({ value })}
          />
        </StarRatingWrapper>
        <RatingForm onSubmit={handlePostRatingSubmit}>
          <CustomText>{t('story-ratings.sectionText-3')}</CustomText>
          <Textarea rows="4" cols="50" placeholder="Optional" value={ratingFeedback} onChange={e => {
            e.persist();
            setRatingFeedback(e.target.value);
          }}/>
          <Button type="submit" marginTop> {t('story-ratings.submitButton')}</Button>
          <Button type="button" marginTop inverted border onClick={closeRating}> {t('story-ratings.cancelButton')}</Button>
        </RatingForm>
        {!!errorMessage &&
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
        }
      </RatingsWrapper>
    </RatingsCenterHelper>
  ) 
};
