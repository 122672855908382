export const useBoxLayout = location => {
  const excludedPages = [
    "/",
    "/es/",
    "/login/",
    "/es/iniciar-sesion/",
    "/register/",
    "/es/registro/",
    "/terms-of-service/",
    "/es/terminos-de-servicio/",
    "/privacy-policy/",
    "/es/politica-de-privacidad/",
    "/erotic-audio-stories/",
    "/reset-password/",
    "/es/restablecer-contrasena/",
    "/for-couples/",
    "/audio-porn-for-women/",
    "/audio-porn-for-couples/",
    "/erotic-asmr/",
    "/es/erotic-asmr/",
    "/audio-sex-stories/",
    "/audio-porn-stories/",
    "/asmr-porn/",
    "/free-audio-stories/",
    "/es/",
    "/stories/",
    "/fantasies/",
    "/scenarios/",
    "/voices/",
    "/erotic-audiobook-stories/",
    "/hot-audio-stories/",
    "/secret-submissive/",
    "/facebook-login/",
    "/es/facebook-login/",
    "/de/",
    "/voice-actor/community/",
    "/de/impressum/",
    '/blog/',
    '/sleep/',
    '/de/schlaf/',
    '/es/sleep/',
    '/es/sueno/',
    '/press-releases/',
    '/es/prensa/',
    '/de/pressemitteilungen/',
    '/lovense/',
    '/de/lovense/'
  
  ]

  let useBoxedLayout = location && excludedPages.indexOf(location.pathname) === -1
  if (useBoxedLayout) {
    const splitLocation = location.pathname.split('/')
    const excludedTemplates = ["fantasy","fantasie","fantasia","accent","blog"]
    useBoxedLayout = !splitLocation.some(urlPart => excludedTemplates.includes(urlPart))
 
  }

  return useBoxedLayout
}
