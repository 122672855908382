import React, {useContext, useEffect, useState, useRef} from 'react'
import AudioPlayerContext from './AudioPlayerContext';
import styled, {keyframes} from 'styled-components'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { useTranslation } from 'react-i18next'
import './style.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { FavHeart } from '../common';
import { FirebaseContext } from '../Firebase';
import { usePageContext } from '../../hooks/usePageContext'
import { useCheckNetworkStatus } from '../../hooks/useCheckNetworkStatus'
import ReaderIconSrc from '../../images/audioPlayer/book.svg'
import FlameIndicator from '../../images/audioPlayer/flame-indicator.svg'
import BackButton from './BackButton'
import MiniPlayer from './MiniPlayer'
import Reader from './Reader'
import LovenseSync from './LovenseSync'
import { LoadingDots } from '../common/LoadingDots';


const PlayerWrapper= styled.div`
  z-index:105;
  width:100%;
  box-shadow: 0 0px 6px 0 hsla(0, 0%, 0%, 0.2);
  margin:auto;
  overflow: hidden;
  @media (min-width: 600px) {
    ${props => (props.isHome && !props.user ) ? 'width:100%;' : 'width:600px;   '};
  }
  bottom:${props => (props.playerMode === "small" && props.user) ? '50px; ' : '0px; '};
  background-color:${props => props.theme.backgroundColor};
  position: fixed;
  height:${props => (props.playerMode === "fullscreen") ? '100%' : '55px'};
  transition: all 0.4s ease-in-out;
  .rhap_container{
    position:absolute;
    height:160px;
    @media (max-width: 320px) {
      height: 150px;
    }
    
    box-shadow:none!important;
    bottom:${props => props.playerMode === "fullscreen" ? ' 0px' : '-150px'};
    transition: all 0.2s ease-in-out;
  } 
  ${props => (props.playerMode === "hidden") ? 'display:none' : ''}; 
`

const FullScreenPlayer = styled.div`
  height: calc(${props => props.fixedVh} * 100px); //this should fix 100vh issue for ios and android see: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  position: absolute;
  ${props => (props.playerMode === "fullscreen") ? 'top:0;' : 'top:500px;'} 
  transition: all 0.2s ease-in-out;
  z-index:100;
`

const CoverWrapper = styled.div`
  max-width:75%;
  border:10px solid white;
  overflow: hidden;
  display:flex;
  align-content: center;
  border-radius: 20px;
  margin: 75px auto 30px auto;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  @media (max-width: 320px) {
    max-width:70%;
    margin:60px auto 20px auto;
  }

  @media (min-width: 600px) {
    max-width:60%;
    margin:60px auto 20px auto;
  }
  
  @media (min-width: 600px) and (max-height: 750px ) {
    max-width:40%;
    margin:60px auto 20px auto;
  }
`

const CustomControlWrapper = styled.div`
  position: relative;
  height:30px;
  width:30px;
  opacity:${props => props.controlActive ? 1 : 0.4} ;
`

const ReaderIconImg = styled.img`
  width:34px;
`

const PlayPauseButtonWrapper = styled.div`
  position:absolute;
  z-index:10;
  bottom:20px;
  right: 50%;
  transform: translateX(50%);
  box-sizing: border-box;
  height:66px;
  width:66px;
  display:flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${props => props.theme.secondaryColor};
  user-select: none;
  &:focus{
    outline:none !important
  }
`

const PlayPauseButton = styled.div`
  // source: https://css-tricks.com/making-pure-css-playpause-button/
  width: 25px;
  height: 30px;
  border-color: transparent transparent transparent ${props => props.theme.backgroundColor};
  transition: 100ms all ease;
  will-change: border-width;
  cursor: pointer;
  border-style: solid;
  margin-left:8px;
  border-width: 15px 0 15px 23px;
  ${props => !props.paused && "border-style: double;"};
  ${props => !props.paused && "border-width:0px 0 0px 20px;"};
  &:focus{
    outline:none !important
  }
`

const LoadingPlayPauseButton = styled.div`
  // source: https://css-tricks.com/making-pure-css-playpause-button/
   position:absolute;
  z-index:10;
  bottom:20px;
  right: 50%;
  transform: translateX(50%);
  box-sizing: border-box;
  height:66px;
  width:66px;
  display:flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${props => props.theme.secondaryColor};
  user-select: none;
  &:focus{
    outline:none !important
  }
`

const StoryTitle = styled.h2`
  font-size: 1.5rem;
  margin: 0 0 5px 0;
  text-align: center;
  @media (max-width: 320px) {
    font-size: 1.5rem;
  }
`

const StorySubTitle = styled.h4`
  text-transform: uppercase;
  font-family: 'Poppins',sans-serif;
  font-size:14px;
  text-align: center;
  color:#858282;
  margin:0px;
`

const Indicator = styled.img`
  width:35px;
  height:50px;
  position: absolute;
  transform: translateX(-50%);
  margin-top: -8px;
  left:${props => props.position ? props.position : 0}%;
`

const IndicatorWrapper = styled.div`
  width:100%;
  height:100%;
  position:relative;
`

const ActionIndicator = ({indicators, audioPlayerRef, duration, show}) => {

  const handleIndicatorClick = (secondsPosition) => {
    audioPlayerRef.currentTime = secondsPosition
  }
  
  return (
    <>
      {show && 
        <IndicatorWrapper>
        {indicators && indicators[0] !== "" && indicators.map((secondsPosition, index) => 
          <Indicator src={FlameIndicator} position={duration !== 0 && parseInt(secondsPosition/duration*100)} onClick={() => handleIndicatorClick(secondsPosition)}/>,
          )}
        </IndicatorWrapper>
      }
    </>
  )

}



const FavoriteHeart = ({color,controlActive}) => {
  const {user,firebase} = useContext(FirebaseContext);
  const {currentStory} = useContext(AudioPlayerContext);
  const [filled, setFilled] = useState(false);

  const handleAddFavoriteClick = () => {
    if(controlActive){
      firebase.toggleFavorite(currentStory.id,user.uid);
      setFilled(!filled);
    }
  }

  return ( 
    <CustomControlWrapper onClick={handleAddFavoriteClick} controlActive={controlActive}>
      <FavHeart filled={filled} color={color} size="30px"/>
    </CustomControlWrapper>
  )
}


const grow = keyframes`
 0%{
    transform: scale(1);
   
  }
  5% {
    transform: scale(1.3);
  }

  10% {
    transform: scale(1);
  }
    
  15% {
    transform: scale(1.3);
  }
  
  20% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.3);
  }
  30% {
    transform: scale(1);
  }
`;


const ReaderIcon = ({controlActive}) => {
  const {setShowReader} = useContext(AudioPlayerContext);
  const handleReaderClick = () => {
    if(controlActive){
      setShowReader(true)
    }
  }

  return (
    <>
    <CustomControlWrapper onClick={handleReaderClick} controlActive={controlActive}>
      <ReaderIconImg src={ReaderIconSrc}/>
    </CustomControlWrapper>
    </>
  )
}


const AudioPlayerWrapper = (props) => {
  const {currentStory, setCurrentStory, playerMode, setPlayerMode, showReader, setShowReader} = useContext(AudioPlayerContext);
  const {user,firebase} = useContext(FirebaseContext);
  const audioPlayerRef = useRef(null)
  const backButtonPressed = useRef(false)
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [fixedVh, setFixedVh] = useState(10);
  const [lovenseData, setLovenseData] = useState("")
  const [currentTime, setCurrentTime] = useState(0);
  const [lovenseInstance, setLovenseInstance] =  useState("")
  const {lang} = usePageContext()
  const isOnline =  useCheckNetworkStatus()
  const { t } = useTranslation('components');
  const hasWindow = typeof window !== 'undefined';

  useEffect(() => {
    /* checks if all criteria are met to display rating form when pressign pause 
        Triggers:
         1. Pause and back button
         2. Pause and listened over 80%
         3. Pause and click on menu right after
         4. Pause and click on x to close player
    */
    const listenDuration = ((end.seconds - start.seconds)); // get the current duration
    const listenedEnough =  listenDuration/parseInt(currentStory.duration) > 0.80 // check if they listened for over 80%
    const paused = audioPlayerRef?.current?.audio?.current.paused // checks if underlaying player is paused
    const interactedWithMenu = props.interactedWithMenu // checks if user has interacted with menu. Passed from layout component
   
    if(paused && !currentStory.alreadyRated && isOnline && (currentStory.type === "full" || currentStory.type === "60-min"  || currentStory.type === "30-min") && (backButtonPressed.current || listenedEnough || interactedWithMenu.current || playerMode === "hidden")) {
      setCurrentStory({ ...currentStory, showRatingForm: true, alreadyRated: true});
    } 
    return () => {
      backButtonPressed.current = false
      interactedWithMenu.current = false // clean up 
    }


  }, [playerMode, start, end, audioPlayerRef?.current?.audio?.current.paused, props.interactedWithMenu, isOnline, currentStory, setCurrentStory ])

 
  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        let vh = window.innerHeight * 0.01
        setFixedVh(vh)
      }
      
      window.addEventListener('resize', handleResize);
      handleResize() // do it once for the first time
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow])
  
  useEffect(() => {
    // get duration in seconds
    const duration = ((end.seconds - start.seconds));
    if(user){
      firebase && firebase.sendUserEvent({
        type:'pause',
        duration:(Math.round(duration * 100) / 100),
        storyId:currentStory.id,
        userId:user.uid,
        audioType:currentStory.type,
        sessionId:currentStory.sessionId,
        audioTime:(Math.round(end.seconds * 100) / 100),
        lang:currentStory.lang
      })
    }
    // send interaction event to GA for better time on page calc
    if(typeof window !== "undefined" && window?.gtag && process.env.NODE_ENV !== "development"){
      window?.gtag('event', `pause-${currentStory.slug}`,{'event_category':'audio'});
    }
  }, [end])

  // simulate expected behaviour of back button 
  useEffect(() => {
    function detectBackButton (e){
      if(playerMode === "fullscreen"){
        window.history.forward();
        backButtonPressed.current = true // treat phone/browser back button the same as our own
        setPlayerMode("small")
      }
  }
    window.onpopstate = detectBackButton;
  }, [])

  // hide welcome audio if user is logged in 
  useEffect(() => {
    if(user && ((currentStory.id === "Welcome Audio") || (currentStory.id === "Welcome Audio Es") || (currentStory.id === "Welcome Audio De"))){
      setPlayerMode("hidden");
    }
  }, [user, currentStory.id])



  useEffect(() => {
    // send pause event even when user leaves page
    if(window){
      window.onbeforeunload = function() {
        const duration = ((Date.now()- start.timestamp)/1000);
        if(user){
          firebase && firebase.sendUserEvent(
            {type:'pause',
            duration:(Math.round(duration * 100) / 100),
            storyId:currentStory.id,
            userId:user.uid,
            audioType:currentStory.type,
            sessionId:currentStory.sessionId,
            audioTime:(Math.round((start.seconds + duration) * 100) / 100),
            lang
          })
        }
      }
    }
  });

  const togglePlay = () => {
    const audioRef = audioPlayerRef?.current?.audio?.current
    const currentTimeMs = Math.round(audioPlayerRef?.current.audio.current.currentTime*1000)
    if (!audioRef.paused) {
      audioRef.pause()
      if(lovenseInstance !== ""){
        lovenseInstance.pause()
      } 
    } else {
      audioRef.play()
      if(lovenseInstance !== "") { 
        lovenseInstance.play({
          speed: 1, // speed
          currentTime: currentTimeMs, // ms
        })
      }
    }
  }

  const handleBackButtonClick = () => {
    setPlayerMode("small")
    backButtonPressed.current = true
  }

  return (
    <>
    <Reader 
      show={showReader} 
      audioPlayerRef={audioPlayerRef?.current?.audio?.current} 
      setShowReader={setShowReader}
      title={currentStory.storyTitle}
      subtitle={currentStory.voiceName}
      content={currentStory.readerContent}
    />
    <PlayerWrapper playerMode={playerMode} isHome={props.isHome} user={user}>
      <MiniPlayer audioPlayerRef={audioPlayerRef?.current?.audio?.current} currentTime={currentTime} togglePlay={togglePlay} lovenseInstance={lovenseInstance}/>
      <FullScreenPlayer playerMode={playerMode} fixedVh={fixedVh}>
        <BackButton onButtonClick={handleBackButtonClick}/>
        <CoverWrapper>
          {currentStory.storyCover && 
            <GatsbyImage image={currentStory.storyCover} alt={`${currentStory.storyTitle} Story Cover`}/>
          }
        </CoverWrapper>
        <div>
          <StoryTitle center>{currentStory.storyTitle}{currentStory.type === "sample" && ` (${t('audio-player-wrapper.sample')}) `}</StoryTitle>
          {currentStory.voiceName !== "placeholder" && <StorySubTitle center>{currentStory.voiceName}</StorySubTitle> }
        </div>
        
          {(lovenseInstance !== "") && (currentStory.lovenseSync && !lovenseData.patternLoaded) && lovenseData.status !== "disconnected"
            ?
              <LoadingPlayPauseButton>
                <LoadingDots scale={0.6} white/>
              </LoadingPlayPauseButton>
            :
              <PlayPauseButtonWrapper onClick={togglePlay}>
                <PlayPauseButton paused={audioPlayerRef?.current?.audio?.current?.paused}/>
              </PlayPauseButtonWrapper>
          }
        
        <AudioPlayer
          onListen={(e) => setCurrentTime(e.target.currentTime)}
          progressJumpSteps={{
            forward: 30000,
            backward: 15000
          }}  
          onSeeked={(e) => {
            if(currentStory.lovenseSync){
              lovenseInstance.change({
                speed: 1, // The media playback speed (1 is normal speed)
                currentTime: parseInt(e.target.currentTime*1000), // The playing time duration so far (in ms)
              })
            } 
          }}
          autoPlayAfterSrcChange={currentStory.autoplay}
          src={currentStory.audioRef}
          ref={audioPlayerRef}
          customProgressBarSection={[
            <ActionIndicator audioPlayerRef={audioPlayerRef?.current?.audio?.current} indicators={currentStory?.indicators} duration={currentStory.duration} show={currentStory.type === 'full'} />,
            RHAP_UI.PROGRESS_BAR,
            RHAP_UI.CURRENT_TIME,
            RHAP_UI.DURATION
          ]}
          customAdditionalControls={[<FavoriteHeart controlActive={currentStory.type === 'full'} color="#F19D9A"/>]}
          customVolumeControls={[<ReaderIcon controlActive={currentStory.type === 'full' && currentStory.readerContent !== ""}/>]}
          customIcons={{
            forward: <img alt="forward-icon" style={{maxWidth:"100%", maxHeight:"100%", userSelect: "none"}} src={require('../../images/audioPlayer/forward-30.svg').default} />,
            rewind: <img alt="rewind-icon" style={{maxWidth:"100%", maxHeight:"100%", userSelect: "none"}} src={require('../../images/audioPlayer/rewind-15.svg').default} />
          }}
          showVolumeControl = {false}
          onPlay={e => {
            setStart({
              seconds:e.target.currentTime,
              timestamp: Date.now()
            });
            if(user){
            firebase && firebase.sendUserEvent(
              {type:'play',
                storyId:currentStory.id,
                userId:user.uid,
                audioType:currentStory.type,
                sessionId:currentStory.sessionId,
                audioTime:(Math.round(e.target.currentTime * 100) / 100),
                lang
              })
            }
            if(typeof window !== "undefined" && window.gtag){
              window.gtag('event', `play-${currentStory.slug}`,{'event_category':'audio'});
            }
          }}
          onPause={e => {
            setEnd({
              seconds:e.target.currentTime,
              timestamp: Date.now()
            });
          }}        
        />
      </FullScreenPlayer>
      {currentStory.lovenseSync && 
        <LovenseSync 
          currentStory={currentStory} 
          audioPlayerRef={audioPlayerRef} 
          lovenseInstance={lovenseInstance}
          setLovenseInstance={setLovenseInstance}
          lovenseData={lovenseData}
          setLovenseData={setLovenseData}
        />
      }
    </PlayerWrapper> 
    </>    
  );
}

export default AudioPlayerWrapper;



