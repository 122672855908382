import React, {useState, useEffect, useRef} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {FirebaseContext, useAuth} from '@components/Firebase'
import { createGlobalStyle } from "styled-components"
import UserMenu from "./UserMenu"
import Footer from "./Footer"
import AudioPlayerContext from "@components/audioplayer/AudioPlayerContext"
import AudioPlayerWrapper from '@components/audioplayer/AudioPlayerWrapper'
import {ThemeProvider} from 'styled-components'
import LightTheme from '../../themes/LightTheme'
import UTMContext from "../UTMContext"
import i18next from '../../i18n/config'
import { useBoxLayout } from '@hooks/useBoxLayout'
import { StoryRatings } from '../common';
import ProfileContext from '@hooks/useProfileContext'

const GlobalStyle = createGlobalStyle`
* {  
  box-sizing: border-box;
}
  html {
    background-color:#ededed;
    box-sizing: border-box;
  }
  body {
    min-height: 100vh;
    position:relative;
    width:100%;
    margin:0px;
    color:#272727;
    padding:${props => props.user ? '0px 0px 50px 0px;' : ' 0px 0px 0px 0px;'};
    padding:${props => (props.playerMode === "small" && props.user) && '0px 0px 100px 0px;'};
    overflow:${props => (props.playerMode === "fullscreen") ? 'hidden;' : ''};
    background-color:white;
    @media ${props =>props.theme.boxLayoutQuery} {
      ${props => (props.boxLayout || props.user && !props.pageExcluded ) ?'width:600px;margin:20px auto 0px auto;box-shadow: 13px 23px 62px -6px rgba(0,0,0,0.27);': 'width:100%;'};
    }
  }
  a{
    color:#272727;
  }
  h1,h2,h3,h4,h5 {
    font-family: 'Romana Becker Demi','Book Antiqua Bold', serif; 
    font-weight: normal; 
    font-style: normal;
  }
  p{
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    line-height: 1.6
  }
  li{
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
  }
  .swiper-pagination{
    bottom: -30px!important;
  }
  .swiper-pagination-bullet-active{
    
    background: ${props => props.theme.secondaryColor}!important;
  }
  .swiper-pagination-bullet{
    background: ${props => props.theme.secondaryColor}!important;
  }
`

const welcomeAudios = {
  en:{
    id:"Welcome Audio",
    path:"/manual_storage/welcomeAudio/welcome.mp3",
    title:"Welcome to Audiodesires!",
  },
  es:{
    id:"Welcome Audio Es",
    path:"/manual_storage/welcomeAudio/es/welcome.mp3",
    title:"¡Bienvenido a Audiodesires!",
  },
  de:{
    id:"Welcome Audio De",
    path:"/manual_storage/welcomeAudio/de/welcome.mp3",
    title:"Willkommen bei Audiodesires!",
  }
}

const checkPageForExclusion = (location) => location.pathname.includes("voice-actor/community") 
  || location.pathname.includes("blog") 
  || location.pathname === '/press-releases/' || location.pathname === '/de/pressemitteilungen/' || location.pathname === '/es/prensa/'
  || location.pathname === '/lovense/' || location.pathname === '/de/lovense/'


function extractUTMParameter(location){
  try {
    let urlParameter = location.search.substring(1);
    let obj = urlParameter.split('&').reduce((result, parameter)=>{
      if(parameter.startsWith("utm_medium") || parameter.startsWith("utm_source") || parameter.startsWith("utm_campaign")){
        let [key, value] = parameter.split('=');
        result[key] = value;
      } 
      return result;
    }, {});
    return obj
  } catch (e) {
    console.log(e)
  }
}


const Layout = ({ children, location, pageContext}) => {

  const PageContext = React.createContext({});
  const data = useStaticQuery(graphql`query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
    welcome: file(relativePath: {eq: "welcome-artwork.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 120, layout: CONSTRAINED)
      }
    }
  }
  `)

  // every time user fb or loading changes layout rerenders
  const {user, setUser, firebase, loading} = useAuth();
  const [playerMode, setPlayerMode] = useState("hidden");
  const [showReader, setShowReader] = useState(false);
  const [UTMParameter, setUTMParameter] = useState('');
  const [currentStory, setCurrentStory] = useState({audioRef:""});
  const [profileInit, setProfileInit] = useState('login')
  const isMobileOpt = useBoxLayout(location)
  const interactedWithMenu = useRef(false)
  const isHome = location && location.pathname === "/";
  const {lang} = pageContext
  

  // check lecure 59
  useEffect(() => {
    if(window && user && !!user.token && (profileInit === 'done' || profileInit === 'login' )){
      window.location.href = `https://bloomstories.com/${lang === "en" ? "": `${lang}/`}login?token=${user.token}&redirectSource=audiodesires`;
    }
  }, [user,profileInit])


  useEffect(()=> {
    // extract and set UTM Parameter
    setUTMParameter(extractUTMParameter(location))
  },[])

  useEffect(() => {
    if(!loading){
      if(!user && i18next){
        fetchAudio(welcomeAudios[pageContext.lang].path)
      }
    }
  },[user, loading]);

  async function fetchAudio(audioRef) {
    const storageRef = await firebase.storage.ref().child(audioRef);
    storageRef.getDownloadURL().then(url => {
      setPlayerMode("small")
      setCurrentStory({
        id: welcomeAudios[pageContext.lang].id,
        storyTitle: welcomeAudios[pageContext.lang].title,
        type:"welcome",
        voiceName: "Audiodesires",
        audioRef: url,
        storyCover: data.welcome.childImageSharp.gatsbyImageData,
        slug:"welcome-audio",
        alreadyRated: true,
        autoplay:false,
        thumbnail: data.welcome.childImageSharp.gatsbyImageData,
      })
      return
    }).catch(function(error) {
      console.log(error); 
    })
  }
 
  return (
    <FirebaseContext.Provider value={{user, firebase, loading, setUser}}>
      <ThemeProvider theme={LightTheme}>
        <ProfileContext.Provider value={{profileInit, setProfileInit}}>
        <AudioPlayerContext.Provider value={{currentStory, setCurrentStory, playerMode, setPlayerMode, showReader, setShowReader}}>
          <UTMContext.Provider value={{UTMParameter, setUTMParameter}}> 
            <GlobalStyle  playerMode={playerMode} boxLayout={isMobileOpt} user={user} pageExcluded={checkPageForExclusion(location)}/>
            <main>{children}</main>
            <AudioPlayerWrapper 
              boxLayout={isMobileOpt} 
              isHome={!isMobileOpt}
              interactedWithMenu={interactedWithMenu}
            />
            <StoryRatings />
            { user ? 
              <UserMenu 
                currentPage={location && location.pathname}  
                siteTitle={data.site.siteMetadata.title} 
                onUserMenuInteraction={()=> interactedWithMenu.current = true} 
                hide={checkPageForExclusion(location)}
                user={user}
              />
              :
              <Footer boxLayout={isMobileOpt} isHome={isHome} siteTitle={data.site.siteMetadata.title} /> 
            }
          </UTMContext.Provider> 
        </AudioPlayerContext.Provider>
        </ProfileContext.Provider>
      </ThemeProvider>
    </FirebaseContext.Provider>
   
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout



