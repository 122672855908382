import { useStaticQuery, graphql, Link } from "gatsby"
import React, {useEffect } from "react"
import styled from 'styled-components'
import { GatsbyImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { useTranslation } from 'react-i18next'
import { usePageContext }  from '@hooks/usePageContext'
import twitter from  "@images/twitter.svg"
import instagram from "@images/instagram.svg" 
import applePodcast from  "@images/logos/apple-podcast-round.svg"
import spotify from  "@images/logos/spotify-round.svg"
import pintrest from  "@images/logos/pintrest.svg"
import youtube from  "@images/youtube.svg"
// import soundcloud from  "@images/logos/soundcloud-round.svg"

const FooterWrapper = styled.footer`
  position:relative;
  bottom: 0px;
  width: 100%;
  padding: 70px 50px;
  justify-content: space-between;
  align-items:end;
  flex-direction:row;
  flex-wrap: wrap;
  display:flex;
  background-color:${props => props.theme.darkGrey};
`

const FooterColumn = styled.div`
  margin:20px 0px;
  ${props => props.hidden ?  'display:none' : 'display:flex;'};
  color:white;
  width: 300px;
  flex-direction: column;
  @media ${props => props.theme.desktopUp && !props.boxLayout} {
    margin:0px 0px;
    width: 200px;
  }
`

const FooterColumnBig = styled.div`
  flex-wrap: wrap;
  color:white;
  width: 300px;
  display:flex;
  flex-direction: row;
  margin:20px 0px;
  @media ${props => props.theme.desktopUp && !props.boxLayout} {
    margin:0px 0px;
  }
`

const FooterColumnBigLogo = styled(FooterColumnBig)`
  flex-direction: column;
`

const Logo = styled(GatsbyImage)`
`


const LogoWrapper = styled.div`
width:200px;
`


const LogoLink = styled(Link)`
 
`

const SocialIcon = styled.img`
  max-width:50px;

`


const FooterTitle = styled.h4`
  font-family:${props => props.theme.mainFontParagraph};
  text-transform:uppercase;
  font-weight:500;
  font-size: 0.8rem;
  color:#bbbbbb;
  margin: 10px 0px;
`
const FooterLink = styled(Link)`
  font-weight:500;
  font-size: 0.8rem;
  margin: 5px 0px;
  font-family:${props => props.theme.mainFontParagraph};
  color: white;
  text-decoration: none;
  &:hover{  
    text-decoration: underline;
  }
`

const FooterExternalLink = styled.a`
  font-weight:500;
  font-size: 0.8rem;
  margin: 5px 0px;
  font-family:${props => props.theme.mainFontParagraph};
  color: white;
  text-decoration: none;
  &:hover{  
    text-decoration: underline;
  }
`


const SocialLink = styled(OutboundLink)`
  width: 50px;
  margin-right:20px;
  margin-bottom: 10px;
  @media ${props => props.theme.phonePort} {
    width: 40px;
  }

`


const Footer = () => {
  const { t  } = useTranslation()
  const {lang, paths, languages} = usePageContext()

  let isMounted = true;

 // check lecure 59
 useEffect(() => {
   return () => {
     // eslint-disable-next-line react-hooks/exhaustive-deps
     isMounted = false;
   }
 }, [])

  const data = useStaticQuery(graphql`{
    logoWhite: file(relativePath: {eq: "logo_white3.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 200, layout: CONSTRAINED)
      }
    }
  }
  `)

  return (
    <FooterWrapper>
      <FooterColumnBigLogo>
        <LogoLink aria-label="Homepage" to={t('urls.root')}>
        <LogoWrapper>
          {isMounted && <Logo image={data.logoWhite.childImageSharp.gatsbyImageData} alt={"Audiodesires Logo"}/>}
          </LogoWrapper>
        </LogoLink>
        {languages.length > 1 &&
          <>
            <FooterTitle>{t('footer.langSwitch')}</FooterTitle>
            <FooterLink to={paths.en}>English</FooterLink>
            <FooterLink to={paths.es}>Español</FooterLink>
            <FooterLink to={paths.de}>Deutsch</FooterLink>
          </>
        }
      </FooterColumnBigLogo>
      <FooterColumn >
      
        {lang === 'en' 
        ?
          <>
            <Link to={'/blog'}><FooterTitle>BLOG</FooterTitle></Link>
            <FooterTitle>{t('footer.title-1')}</FooterTitle>
            <FooterLink to="/erotic-audio-stories/">Erotic Audio</FooterLink>
            <FooterLink to="/audio-sex-stories/">Audio Sex</FooterLink>
            <FooterLink to="/audio-porn-stories/">Audio Porn</FooterLink>
            <FooterLink to="/erotic-asmr/">Erotic ASMR</FooterLink>
          </>
     
        :
        <>
          {/* <FooterLink to="es/erotic-asmr/">ASMR Erótico</FooterLink> */}
        </>
        }   
        </FooterColumn>
      <FooterColumn>
        
        <FooterTitle>{t('footer.company')}</FooterTitle>
        {lang === 'de' && <FooterLink to={t('urls.imprint')}>Impressum</FooterLink>}
        <FooterLink to={t('urls.terms-of-service')}>{t('footer.termsOfService')}</FooterLink>
        <FooterLink to={t('urls.privacy-policy')}>{t('footer.privacyPolicy')}</FooterLink>
        <FooterExternalLink href="https://drive.google.com/drive/folders/1lbA3OiYYpcPnbggSoWvtq7JpL1oxSmSJ?usp=sharing" target="_blank">{t('footer.press')}</FooterExternalLink>
        <FooterExternalLink href="mailto:support@audiodesires.com">{t('footer.support')}</FooterExternalLink>
      </FooterColumn>
      <FooterColumnBig>
        <SocialLink rel="noopener" aria-label="Youtube" href="https://www.youtube.com/channel/UCKkq0sbJ7mi0Hud5Jv9WdaQ" target="_blank">
          <SocialIcon alt="Youtube Icon"src={youtube} loading="lazy"/>
        </SocialLink>
        <SocialLink rel="noopener" aria-label="Twitter" href="https://twitter.com/audiodesirescom" target="_blank">
          <SocialIcon alt="Twitter Icon" src={twitter} loading="lazy"/>
        </SocialLink>
        <SocialLink rel="noopener" aria-label="Instagram" href="https://www.instagram.com/audiodesirescom/" target="_blank">
          <SocialIcon alt="Instagram Icon" src={instagram} loading="lazy"/>
        </SocialLink>
        <SocialLink rel="noopener" aria-label="Apple Podcast" href="https://podcasts.apple.com/de/podcast/erotic-audio-by-audiodesires-com/id1506014619/" target="_blank">
          <SocialIcon alt="Apple Podcast Icon" src={applePodcast} loading="lazy"/>
        </SocialLink>
        <SocialLink rel="noopener" aria-label="Spotify" href="https://open.spotify.com/show/0sjeplaBuFWzOxjThAum9g" target="_blank">
          <SocialIcon alt="Spotify Icon" src={spotify} loading="lazy"/>
        </SocialLink>
        {/* <SocialLink rel="noopener" aria-label="Soundcloud" href="https://soundcloud.com/audiodesires_official" target="_blank">
          <SocialIcon alt="Soundcloud Icon" src={soundcloud} loading="lazy"/>
        </SocialLink> */}
        <SocialLink rel="noopener" aria-label="Pintrest" href="https://www.pinterest.com/audiodesirescom" target="_blank">
          <SocialIcon alt="Soundcloud Icon" src={pintrest} loading="lazy"/>
        </SocialLink>
      </FooterColumnBig>


    </FooterWrapper>
  );
}


export default Footer
