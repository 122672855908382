import firebaseConfig from "./config";
import app from "firebase/compat/app"

class Firebase {
  constructor() {
    if(!firebaseInstance) {
      app.initializeApp(firebaseConfig);
      this.auth = app.auth();
      this.db = app.firestore();
      this.functions = app.functions();
      this.storage = app.storage();
    }
  }

        /*  const rawResponse = await fetch('https://us-central1-audiodesires-aab7f.cloudfunctions.net/auth-getCookie', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({idToken: idToken})
          });
          const content = await rawResponse.json();
          console.log(content); */



  async customLogin({email,password}){
    //https://dev.to/brianburton/cross-domain-firebase-authentication-a-simple-approach-337k
    //https://firebase.google.com/docs/auth/admin/manage-cookies

    // other way 
    //https://dev.to/johncarroll/how-to-share-firebase-authentication-across-subdomains-1ka8
    // When the user signs in with email and password.
    try{
      /* this.auth.signInWithEmailAndPassword(email, password) */
      const user = await this.auth.signInWithEmailAndPassword(email,password)
      const token = await user.user.getIdToken()
      console.log("token",token)
      return token

      
     /*  .then(async (user) => {
        const token = await user.user.getIdToken()
        console.log("token",token)
        return token
      }); */
    } catch(e){console.log(e)}
  }

  async checkPromotionCode(code){
    const checkPromoCodeCallable = this.functions.httpsCallable('promotion-checkPromotionCode');
    return await checkPromoCodeCallable({code});
  }

  async checkPartnerCode(code){
    const checkPartnerCodeCallable = this.functions.httpsCallable('promotion-checkPartnerCode');
    return await checkPartnerCodeCallable({code});
  }

  // used to update claim for promo popups
  updatePromoPopupClaim(){
    const updateValPromoClaim = this.functions.httpsCallable('updatePromoClaim')
    return updateValPromoClaim()
  };


  async sendUserEvent(event){
    const logUserEventCallable = this.functions.httpsCallable('logUserEvent');
    return logUserEventCallable(event);
  }


  async getLovenseToken(event){
    const getToken = this.functions.httpsCallable('getLovenseToken');
    return getToken(event);
  }

  async trackLogin(userId, method, language){
    // send user event to database
    const logUserEventCallable = this.functions.httpsCallable('logUserEvent');
      
    // send event to google analytics
    if(typeof window !== "undefined" && process.env.NODE_ENV !== "development"){
      await window?.gtag('event', 'login', { method : method })
    }
    return logUserEventCallable({type:"login",userId:userId,lang:language})
  }

  async trackRegister(method, language){
    if(typeof window !== "undefined" && window.gtag && process.env.NODE_ENV !== "development"){
      window.gtag('event', 'sign_up', { method : method });
      window.gtag('event', 'conversion', {'send_to': 'AW-955594792/r_vDCMOV7eQBEKjw1McD'});
    }
  }

  async applyDiscount(){
    const applyCancelDiscount =  this.functions.httpsCallable('stripe-applyCancelDiscount');
    await applyCancelDiscount().catch((error) => {
      console.log(error.message)
       throw(error)
       
    });
    return;
  }

  async fetchAudio(audioRef) {
    const storageRef = await this.storage.ref().child(audioRef);
    await storageRef.getDownloadURL().then(url => {
      return url
    }).catch(function(error) {
      //console.log(error); 
    })
  }

  getUserProfile({userId, onSnapshot}){
    return this.db.collection('profiles').doc(userId).onSnapshot(onSnapshot)
   
  } 

  // add favorites to database 
  async toggleFavorite(storyId, userId){
    let profileRef = this.db.collection('profiles').doc(userId);
    // get list of favorites
    let old = await profileRef.get()
    let favTemp = old.data().favorites;
    // check if story is alread in favorites
    const index = favTemp.indexOf(storyId)
    if(index > -1){
      favTemp.splice(index, 1);
    } else {
      favTemp.push(storyId) //push new favorite 
    }
    profileRef.set({favorites: favTemp},{merge: true})
    return 
  }

  async checkForHeartStatus(storyId, userId){
    let profileRef = this.db.collection('profiles').doc(userId);
    let data = await profileRef.get()
    const index = data.data().favorites.indexOf(storyId)
    if(index > -1){
      return  true
    } else {
      return false
    }
  }

  subscribeToFavorites({userId, onSnapshot}){
    return  this.db.collection('profiles').doc(userId).onSnapshot(onSnapshot);
  }

  subscribeToStripeProfile({userId, onSnapshot}){
    return  this.db.collection('stripeProfiles').doc(userId).onSnapshot(onSnapshot);
  }

  async createVoice({voiceName, voiceGender, voiceLanguage}){
    const createVoiceCallable = this.functions.httpsCallable('content-createVoice');
    return createVoiceCallable({
      voiceName,
      voiceGender,
      voiceLanguage
    });
  }

  async sendCancelFeedback(userfeedback,lang){
    const logCancelFeedbackCallable = this.functions.httpsCallable('logCancelFeedback');
    await logCancelFeedbackCallable({"feedback":`${userfeedback.reason}: ${userfeedback.text}`,lang:lang}).catch((error) => {
      console.log(error.message)
      // throw(error)
    });
    return 
  }

  async createUserProfile(userData){
    try {
      const {lang, uid, UTMParameter, method, email } = userData
      await this.db.collection('profiles').doc(uid)
        .set({
          email,
          registerLanguage: lang,
          dateCreated: new Date(),
          favorites: [],
          UTMParameter
        }, {merge: true})

        this.trackRegister(method,lang)
    } catch (err) {
      console.log(err)
    }
  }

  async socialLogin(socialprovider,type,lang,UTMs, setProfileInit){
    try{
      const defaultParameter  = {
        utm_campaign:"",
        utm_medium:"",
        utm_source:""
      }
      const constructedParameter = {...defaultParameter,...UTMs}
      let provider;

      if(socialprovider === "Google"){
        provider = new app.auth.GoogleAuthProvider()
      } else if (socialprovider === "Facebook"){
        provider = new app.auth.FacebookAuthProvider();
      }

      const result = await this.auth.signInWithPopup(provider)
      const userExists = await this.db.collection('profiles').doc(result.user.uid).get()

      if(userExists.exists){
        // console.log("social:LOGIN")
        await this.trackLogin(result.user.uid,socialprovider,lang)
      } else {
        // console.log("social:REGISTER")
        // set proper language for email templates 
        this.auth ? this.auth.languageCode = lang : this.auth.languageCode = 'en'

        setProfileInit('register')
        await this.createUserProfile({
          lang,
          email:result.user.email,
          uid:result.user.uid,
          UTMParameter:constructedParameter,
          method:provider,
        })
        setProfileInit('done')
        return
      }
    } catch (err) {
      console.log(err)
    }
  }

  async register({email, password, lang, UTMs, setProfileInit}){
    try{
      const defaultParameter  = {
        utm_campaign:"",
        utm_medium:"",
        utm_source:""
      }
      const constructedParameter = {...defaultParameter,...UTMs}

      // set proper language for email templates 
      this.auth ? this.auth.languageCode = lang : this.auth.languageCode = 'en'
      setProfileInit("register")
      const result = await this.auth.createUserWithEmailAndPassword(email,password)
      await this.createUserProfile({
        lang,
        email,
        uid:result.user.uid,
        UTMParameter:constructedParameter,
        method:'Email',
      })
      setProfileInit("done")
      this.auth.currentUser.sendEmailVerification()
      return 

    } catch (err){
      console.log(err)
      throw(err)
    }
  }

  /* ----------------------- Profile  ------------------------------- */
  sendResetEmail(email){
    this.auth.sendPasswordResetEmail(email);
  }

  async cancelSubscription(){
    const cancelSubscriptionCallable = this.functions.httpsCallable('stripe-cancelSubscription')
     await cancelSubscriptionCallable().catch((error) => {
       console.log(error.message)
        throw(error)
    });
    return true
  }

  async changePassword(password){
    return await this.auth.currentUser.updatePassword(password);
  }

  async deleteUser(){
    let user = this.auth.currentUser;
    user.delete().then(function() {
      return true
    }).catch((error) => {
      throw(error)
    });
  }

  async postRating({feedback, storyId, stars, starsVoice, lang}){  
    const postCommentCallable = this.functions.httpsCallable('postRating');
    return postCommentCallable({
      feedback,
      storyId,
      stars,
      starsVoice,
      lang
    });
  }

  async login({email, password,lang}) {
    await this.auth.signInWithEmailAndPassword(email, password)
    this.trackLogin(this.auth.currentUser.uid,'Email',lang)
    return ;
  }

  async logout(lang) {
    this.sendUserEvent({type:"logout",userId:this.auth.currentUser.uid,lang})
    await this.auth.signOut();
  }

  // ------------------------------------------ PayPal ---------------------------------------------
  async cancelPaypal(){
    const cancelSubscriptionCallable = this.functions.httpsCallable('paypal-cancelSubscription')
    return await cancelSubscriptionCallable()
  }

  async paypalClientApprove(){
    const frontendApproved = this.functions.httpsCallable('paypal-paypalFrontendApproved')
    return await frontendApproved()
  }

  subscribeToPaypalProfile({userId, onSnapshot}){
    return  this.db.collection('paypalProfiles').doc(userId).onSnapshot(onSnapshot);
  }

  async trackSearchQuery({query,lang}){
    const trackSearchQueryCallable = this.functions.httpsCallable('trackSearchQuery');
    return trackSearchQueryCallable({
      query,
      lang
    });
  }
}

let firebaseInstance;

function getFirebaseInstance() {
  if(!firebaseInstance && app){
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  }else if(firebaseInstance){
    return firebaseInstance
  }else{
    return null;
  }
}

export default getFirebaseInstance;
