import React from "react"
import styled from 'styled-components'
import backgroundWave from "../../images/wave-landing.svg"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import starIcon from '../../images/icons/star.svg'
import 'swiper/css/bundle'

const CallToActionSection = styled.section`
  padding: 260px 0px;
  display:flex;
  align-items: center;
  justify-content:center;
  background-image: url(${backgroundWave});
  background-repeat: no-repeat;
  background-size: contain;
  background-position:center;
  @media ${props => props.theme.phoneLand} {
    padding: 150px 0px;
    background-size: cover;
  }
  @media (min-width: 1950px){
    height:1000px;
  }
`

const StoryCover = styled(GatsbyImage)`
  border-radius: 5px;
  width:75px;
  height:75px;
  margin-right:25px;
  @media ${props => props.theme.phonePort} {
    width:80px;
    height:80px;
  }
`

const TestimonialWrapper = styled.div`
  
  margin-bottom: 20px;
`

const CustomerMeta = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
`

const StoryName = styled(Link)`
  color:#272727;
  font-family:${props => props.theme.mainFontTitle};
  font-weight: 700;
  text-decoration:none;
  font-size:1.3rem;
` 

const StoryMeta = styled.p`
  font-size:12px;
  margin: 0px 0px;
  text-transform:uppercase;
  font-family: ${props => props.theme.mainFontParagraph};
  color: #7a6664;
  font-weight:500;
`

const TestimonialText = styled.h2`
  max-width: 500px;
  color:#272727;
  text-align:center;
  font-size:2rem;
  margin:20px 0 30px 0;
  @media ${props => props.theme.phonePort} {
    max-width: 90%;
    font-size:1.8rem;
  }
`

const StoryInfoWrapper = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
`

const CustomerTextWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StarIcon = styled.img`
  width:20px;
  height:20px;
  margin:2px;
`

const RatingWrapper = styled.div`
  display:flex;
  justify-content: center;
`

export const TestimonialSection = () => {
  const { t } = useTranslation(['testimonials','translations'])

  const data = useStaticQuery(graphql`
  {
 
    fireice: file(relativePath: {eq: "testimonials/fire-ice.jpeg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 130, layout: CONSTRAINED)
      }
    }
    muse: file(relativePath: {eq: "testimonials/muse.jpeg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 130, layout: CONSTRAINED)
      }
    }
    nightclubheat: file(relativePath: {eq: "testimonials/nightclub-heat.jpeg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 130, layout: CONSTRAINED)
      }
    }
    ranchhandparttwo: file(relativePath: {eq: "testimonials/ranch-hand-part-two.jpeg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 130, layout: CONSTRAINED)
      }
    }

    tujefa: file(relativePath: {eq: "testimonials/your-boss.jpeg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 130, layout: CONSTRAINED)
      }
    }
    arboldecerezos: file(relativePath: {eq: "testimonials/cherry-tree.jpeg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 130, layout: CONSTRAINED)
      }
    }
    nochedebar: file(relativePath: {eq: "testimonials/nightclub-heat.jpeg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 130, layout: CONSTRAINED)
      }
    }
    lamusa: file(relativePath: {eq: "testimonials/muse.jpeg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 130, layout: CONSTRAINED)
      }
    }
  }
`)

  const params = {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: false
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
  }
  
  
  return (
    <CallToActionSection>
      <Swiper  {...params}> 
        {t('testimonials', {returnObjects: true}).map(({text, story, storySlug, storyMeta},index) => (
          <SwiperSlide key={index}>
            <TestimonialWrapper>
              <RatingWrapper> 
                <StarIcon src={starIcon}/>
                <StarIcon src={starIcon}/>
                <StarIcon src={starIcon}/>
                <StarIcon src={starIcon}/>
                <StarIcon src={starIcon}/>
              </RatingWrapper>
              <CustomerTextWrapper>
                <TestimonialText center >„{text}“</TestimonialText>
              </CustomerTextWrapper>
              <StoryInfoWrapper>
                <Link to={ `${t('translations:urls.story')}${storySlug}`}> 
                  <StoryCover  alt={"testimonial customer picture"} image={ data[storySlug.replace(/-/g,'')].childImageSharp.gatsbyImageData }/> 
                </Link>
                <CustomerMeta>
                  <StoryName to={ `${t('translations:urls.story')}${storySlug}`}>{story}</StoryName>
                  <StoryMeta>{storyMeta}</StoryMeta>
                </CustomerMeta>
              </StoryInfoWrapper>
            </TestimonialWrapper>
         </SwiperSlide>
        ))}
     </Swiper> 
    </CallToActionSection>
  );
}

