import React from "react";
import styled from 'styled-components'

const ChevronPath = styled.path`
 fill: ${props => props.theme.secondaryColor};
` 

const ChevronSvg = styled.svg`
  height:100%;
  width:100%;
  overflow: visible;
`

const Wrapper = styled.div`
  border-radius: 50%;
  margin-top:30px;
  margin-left:30px;
  z-index:15;
  position:absolute;
  height:30px;
  width:30px;
  @media ${props => props.theme.phoneLand} {
    margin-top:25px;
    margin-left:25px;
  }
`


const BackButton= ({onButtonClick}) => {
  return(
    <Wrapper onClick={onButtonClick}>
      <ChevronSvg  xmlns="http://www.w3.org/2000/svg" width="34.875" height="34.875" viewBox="0 0 34.875 34.875" >
        <ChevronPath d="M18,35.438A17.438,17.438,0,1,1,35.438,18,17.434,17.434,0,0,1,18,35.438ZM9.991,19.2l9.527,9.527a1.681,1.681,0,0,0,2.384,0l1.2-1.2a1.681,1.681,0,0,0,0-2.384L15.954,18,23.1,10.856a1.681,1.681,0,0,0,0-2.384l-1.2-1.2a1.681,1.681,0,0,0-2.384,0L9.991,16.8A1.689,1.689,0,0,0,9.991,19.2Z"/>
      </ChevronSvg>
    </Wrapper>  
  )
}
export default BackButton