import React, {useState} from 'react'
import styled from 'styled-components'


const Wrapper = styled.div`
  position: fixed;
  top:0px;
  left:${props => props.show ? "0":"100%" };
  width:100%;
  height:100vh;
  transition: all 0.5s;
  background-color: ${props => props.theme.backgroundColor};
  z-index:110;
  display: flex;
  flex-direction: column;
  @media ${props =>props.theme.boxLayoutQuery} {
    width:600px;
    margin:auto;
    left:${props => props.show ? "auto":"100%" };
  }
`


const BubbleCommon = `
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 0;
    margin-bottom: -10px;
  }
`


const color1 = "#FFF"
const color2 = "#F19D9A17"
const color3 = "#3B281417"


const Content =styled.div`
  padding:0 5%;
  p{
    font-family: 'Romana Becker Regular',serif;
    font-size: 20px;
  }
  display:block;
  position: relative;
  .vo1{
    ${BubbleCommon};
    ${props => props.showBubbles ? "padding: 10px 2.5%":"padding: 10px 0"};
    ${props => props.showBubbles &&"width: 105%;"}
    margin:${props => props.showBubbles ? "50px 0 50px -2.5%" : "50px 0"};
    background-color:${props => props.showBubbles ? `${color1}` :"#ff00" };
    text-align:left;
    border-radius: 7px 7px 7px 0;
    &:after {
      left: 10px;
      border-top-color: ${props => props.showBubbles ? `${color1}` :"#ff00"};
      border-left: 0;
      margin-left: -10px;
    }
  }
  .vo2{
    ${BubbleCommon};
    ${props => props.showBubbles ? "padding: 10px 2.5%":"padding: 10px 0"};
    ${props => props.showBubbles &&"width: 105%;"}
    margin:${props => props.showBubbles ? "50px 0 50px -2.5%" : "50px 0"};
    border-radius: 7px 7px 0px 7px;
    text-align:right;
    background-color:${props => props.showBubbles ?  `${color2}`:"#ff00"};
    &:after {
      right: 10px;
      border-top-color: ${props => props.showBubbles ? `${color2}` :"#ff00"};
      border-right: 0;
      margin-right: -10px;
    }
  }
  .vo3{
    ${BubbleCommon};
    ${props => props.showBubbles ? "padding: 10px 2.5%":"padding: 10px 0"};
    ${props => props.showBubbles &&"width: 105%;"}
    margin:${props => props.showBubbles ? "50px 0 50px -2.5%" : "50px 0"};
    border-radius: 7px 7px 0px 7px;
    text-align:right;
    background-color:${props => props.showBubbles ?  `${color3}`: "#ff00" };
    &:after {
      right: 10px;
      border-top-color: ${props => props.showBubbles ? `${color3}` :"#ff00"};
      border-right: 0;
      margin-right: -10px;
    }
  }
  .disclaimer{
    font-family: 'Romana Becker Regular',serif;
    font-size:16px;
    margin:50px 0;
    position: relative;
    text-align: center;
    padding: 35px 10px 25px 10px;
    background-color:white;
    border-radius:15px;
    &:before{
      font-family: 'Poppins';
      position: absolute;
      content: "DISCLAIMER";
      left: 0px;
      top: 10px;
      font-size: 16px;
      margin: 0;
      padding: 0;
      color: #F19D9A;
      text-align: center;
      width:100%
    }
  }

`

const PlayPauseButton = styled.div`
  height: 20px;
  border-color: transparent transparent transparent ${props => props.theme.secondaryColor};
  transition: 100ms all ease;
  will-change: border-width;
  cursor: pointer;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  margin-left: ${props => !props.paused ? "0" :"3px"};
  ${props => !props.paused && "border-style: double;"};
  ${props => !props.paused && "border-width:0px 0 0px 18px;"};
  &:focus{
    outline:none !important
  }
`

const TitleWrapper = styled.div`
  width: 100%;
  margin: 60px 0 20px 0;
  >h2{
    color: #353535;
    font-size:28px;
    text-align: center;
    margin-bottom:5px;
  }
  >h3{
    font-family: 'Poppins',sans-serif;
    font-size:14px;
    text-align: center;
    color:#858282;
    margin:0px;
  }
`

const ContentWrapper = styled.div`
  overflow-y: scroll;
`

const ControlButton = styled.div`
  position:absolute;
  display:grid;
  place-items: center;
  height:50px;
  width:50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50%;
`

const PlayPauseButtonWrapper = styled(ControlButton)`
  bottom:25px;
  right:25px;
  background-color: #fff;
`

const ChevronPath = styled.path`
  fill: ${props => props.theme.secondaryColor};
` 

const ChevronSvg = styled.svg`
  height:100%;
  width:100%;
  overflow: visible;
`

const BackButtonWrapper = styled.div`
  background-color:white; 
  position:absolute;
  border-radius:50%;
  width:30px;
  height:30px;
  top:25px;
  left:25px;
  z-index:5;
`

const ToogleSpeechBubbles = styled(ControlButton)`
  bottom:25px;
  right:100px;
  background-color:white;
  padding:10px;
  display:grid;
  place-items:center;
`

const Component = ({audioPlayerRef,show,setShowReader,title,subtitle,content}) => {

  const [showBubbles, setShowBubbles] = useState(true)

  const togglePlay = () => {
    if (!audioPlayerRef.paused) {
      audioPlayerRef.pause()
    } else {
      audioPlayerRef.play()
    }
  }

  const handleClose = () => {
    setShowReader(false)
  }

  return (
  <Wrapper show={show}>
    <BackButtonWrapper onClick={handleClose}>
      <ChevronSvg  xmlns="http://www.w3.org/2000/svg" width="34.875" height="34.875" viewBox="0 0 34.875 34.875" >
        <ChevronPath d="M18,35.438A17.438,17.438,0,1,1,35.438,18,17.434,17.434,0,0,1,18,35.438ZM9.991,19.2l9.527,9.527a1.681,1.681,0,0,0,2.384,0l1.2-1.2a1.681,1.681,0,0,0,0-2.384L15.954,18,23.1,10.856a1.681,1.681,0,0,0,0-2.384l-1.2-1.2a1.681,1.681,0,0,0-2.384,0L9.991,16.8A1.689,1.689,0,0,0,9.991,19.2Z"/>
      </ChevronSvg>
    </BackButtonWrapper>
    <ContentWrapper >    
      <TitleWrapper>
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
      </TitleWrapper> 
      <Content showBubbles={showBubbles} dangerouslySetInnerHTML={{ __html: content}}/> 
    </ContentWrapper>
    <PlayPauseButtonWrapper onClick={togglePlay}>
      <PlayPauseButton paused={audioPlayerRef?.paused}/>
    </PlayPauseButtonWrapper>
    <ToogleSpeechBubbles onClick={() => setShowBubbles(!showBubbles)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 32.23">
          <path d="M16,16.89,43,17a2,2,0,0,1,2,2l0,17.56v4.9l-3.25-3.27L16,38.09a2,2,0,0,1-2-2L14,18.9A2,2,0,0,1,16,16.89Z" transform="translate(-4 -9.2)" fill="#AAAAAA"/>
          <path d="M33.1,9.33l-27-.13a2,2,0,0,0-2,2L4,28.76v1.63l0,3.26L7.28,30.4,33,30.53a2,2,0,0,0,2-2l.09-17.17A2,2,0,0,0,33.1,9.33Z" transform="translate(-4 -9.2)" fill={ showBubbles ? "#696969" : "#F19D9A"}/>
        </svg>
    </ToogleSpeechBubbles>
  </Wrapper>
  )
}

export default Component