import React from "react";
import styled from 'styled-components'

const HeartPath = styled.path`
  fill:${props => props.filled ?  props.color : 'none'};
  stroke:${props => props.color ?  props.color : 'white'};
  stroke-miterlimit:2;
  stroke-width:7px;
` 
const HeartSvg = styled.svg`
  height:100%;
  width:100%;
`
const  Wrapper = styled.div`
  height:${props => props.size};
  width:${props => props.size};
  display: flex;
  align-items:center;
  justify-content:center;

`
export const FavHeart = ({filled, color="white", size="30px"}) => {
  return (
    <Wrapper size={size} >
      <HeartSvg  viewBox="0 0 100 88">
        <HeartPath filled={filled} color={color} d="M88,8.4C77.7,0,62.7,1.1,53.7,10.9L50,14.6l-3.6-3.7C37.4,1.1,22.3,0,12,8.4
	C0.9,17.9-0.3,34.6,9.3,45.7c0.3,0.3,0.6,0.7,0.9,1l35.7,36.8c2.2,2.3,5.9,2.4,8.2,0.2c0.1-0.1,0.1-0.1,0.2-0.2l35.7-36.8
	C99.9,36.1,99.6,19.4,89,9.3C88.7,9,88.4,8.7,88,8.4L88,8.4z"/>
      </HeartSvg>  
    </Wrapper>  
  )
}


