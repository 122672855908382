import React from "react"
import styled from "styled-components"

const InsideWrapper = styled.div`
  background-color: #f19d9a;
  margin-top: -10px;
`


export const WaveSection = ({ children }) => {
  return (
    <>
      <svg
        id="svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 595.28 57.87"
      >
        <path
          d="M0,57.87V6.27c34.71,3.6,69.42,7.2,122.36,5.41s124.12-9,183.55-11S413,1.76,459.27,3.56s91.14,2.25,136,2.71v51.6Z"
          transform="translate(0)"
          fill="#feddd8"
        />
        <path
          d="M0,57.87V27.55c61.18,1.62,122.36,3.24,171.56,5.89S258,39.77,304.25,36.38,405.94,22.51,456,19.86s94.67,2.52,139.32,7.69V57.87Z"
          transform="translate(0)"
          fill="#f19d9a"
        />
      </svg>
      <InsideWrapper>{children}</InsideWrapper>
      <svg id="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595.28 57.87"><title>bottom2</title><path d="M595.28,0V53.12c-59.06,2.33-118.11,4.65-164.12,2.79s-79-7.91-126.49-7.56S195,55.44,141.79,57.3,44.29,56.14,0,53.12V0Z" fill="#feddd8"/><path d="M595.28,0V26.66c-62-3.59-124-7.18-176.52-5s-95.49,10.19-145.1,11.93-105.83-2.77-152.54-5S37.2,26.55,0,26.66V0Z" fill="#f19d9a"/></svg>
    </>
  )
}
