
import i18next from 'i18next';

i18next.init({
    fallbackLng: 'en',
    resources: {
        es: {
            translations: require('../locales/es/translations.json'),
            testimonials: require('../locales/es/testimonials.json'),
            components: require('../locales/es/components.json'),
            pages: require('../locales/es/pages.json'),
            sale: require('../locales/es/sale.json')
        },
        en: {
            translations: require('../locales/en/translations.json'),
            testimonials: require('../locales/en/testimonials.json'),
            components: require('../locales/en/components.json'),
            pages: require('../locales/en/pages.json'),
            sale: require('../locales/en/sale.json'),
        },
        de: {
            translations: require('../locales/de/translations.json'),
            testimonials: require('../locales/de/testimonials.json'),
            components: require('../locales/de/components.json'),
            pages: require('../locales/de/pages.json'),
            sale: require('../locales/de/sale.json'),
        }
    },
    ns: ['translations','testimonials', 'pages','components'],
    defaultNS: 'translations',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
});



i18next.languages = ['es', 'en'];

export default i18next;