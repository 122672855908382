import React, {useState} from "react";
import styled from 'styled-components';
import readMoreIcon from "../../images/readmoreicon.svg";


const ReadMoreIcon = styled.img`
  ${props => props.open ? 'transform:scaleY(-1);': 'transform:scaleY(1);'}
  width:30px;
  transition: transform 0.3s;
`

const Wrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.secondaryColor};
  margin-top: 20px;
  width:100%;
  &:last-child{
    border:none;
  }

`
const Question = styled.h2`
  font-size:1rem;
  margin: 0px;
  font-family:${props => props.theme.mainFontParagraph};
  font-weight:500;
`
const Answer = styled.p`
  display:${props => props.open ? 'block': 'none'};
  opacity:0.9;
  padding: 0px 10px 10px 10px;
  margin: 0px;
`

const ChildWrapper = styled.div`
  display:${props => props.open ? 'block': 'none'};
  opacity:0.9;
  padding: 0px 10px 10px 10px;
  margin: 0px;
`

const QuestionWrapper = styled.div`
  padding: 10px 10px;
  display:flex;
  align-items:center;
  justify-content:space-between;
`

export const  Collapsible = ({question, answer, children}) => {
  const [open, setOpen] = useState(false);

  function togglePanel(){
   setOpen(!open);
  }


  return (
    <Wrapper>
      <QuestionWrapper onClick={togglePanel} > 
        <Question>{question} </Question>
        <ReadMoreIcon alt="Read more Button"open={open} src={readMoreIcon}/>
      </QuestionWrapper>
      {answer  && <Answer open={open} dangerouslySetInnerHTML={{ __html: answer}}/>}
      {children  && <ChildWrapper open={open}>{children}</ChildWrapper>}
    </Wrapper>
  );
}