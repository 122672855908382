import styled from 'styled-components';
export const Textarea = styled.textarea`
  width:100%;
  padding:15px 10px;
  font-size:14px;
  margin:8px auto;
  border-radius:4px;
  border:none;
  box-shadow:none;
  background:#f19d9a42;
  font-family: ${props => props.theme.mainFontParagraph};
`
export default Textarea