import styled from 'styled-components'
import React from "react"

const PrincipleWrapper = styled.div`
  margin: 20px 20px;
  max-width: 300px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
`

const BlurbImage = styled.img`
  max-width: 200px;
`
const BlurbTitle= styled.h2`
  margin: 20px 0px;
  text-align:center;
`

const BlurbText= styled.p`
  text-align:center;
`


export const PrincipleBlurb = ({image, title, text}) => {
  return(
    <PrincipleWrapper>
      <BlurbImage alt={`${title} Icon`} src={image} loading="lazy"/>
      <BlurbTitle>
        {title}
      </BlurbTitle>
      <BlurbText>
        {text}
      </BlurbText>
    </PrincipleWrapper>
  )
}

