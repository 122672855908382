import styled from 'styled-components';
export const FantasyTag = styled.div`
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-family: 'Poppins';
  text-transform:uppercase;
  margin-right:15px;
  color:#F19D9A;
  padding: 5px 5px;
  border: 1px solid #F19D9A;
  border-radius: 5px;
  white-space:nowrap;
`