import React from "react"
import styled from "styled-components"
import { BackButton } from './'

const TopWrapper = styled.div`
  display: flex;
  padding: 5% 0 20px 0;
  background-color: ${props => props.theme.backgroundColor};
  align-items: center;
`

const PageTitleWrapper = styled.div`
  width: 100%;
  position: absolute;
  text-align: center;
  @media (min-width: 600px) {
    width:600px;
  }
`

const PageTitle = styled.h1`
  text-align:center;
  font-family: ${props => props.theme.mainFontHeading};
  font-size:25px;
`


const BackButtonWrapper = styled.div`
  margin: 0  0  0 5%; 
  z-index:5;
`

export const AppPageHeader = ({pageTitle, }) => {

  return (
    <TopWrapper>
      <BackButtonWrapper>
        {" "}
        <BackButton />
      </BackButtonWrapper>
      <PageTitleWrapper>
        <PageTitle>{pageTitle}</PageTitle>
      </PageTitleWrapper>
    </TopWrapper>
  )
}


