import { Link} from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from 'styled-components';
import heart from "@images/menu/heart.svg";
import search from "@images/menu/search.svg";
import home from "@images/menu/home.svg" ;
import signs from "@images/menu/direction-signs.svg";
import sleep from "@images/menu/sleep.svg";
import community from "@images/menu/community2.svg";
import { useTranslation } from 'react-i18next';
import { usePageContext }  from '@hooks/usePageContext';

const NavItemWrapper = styled(Link)`
  display:flex;
  flex-direction:column;
  padding:2px 0px 0px 0px;
`

const NavLink = styled.p`
  margin: 0px;
  font-family:'Poppins';
  color:${props => props.isPage ?'white' : '#bfbfbf' };
  font-size:10px;
  text-decoration: none;
  &:focus{
    text-decoration: none;
  }
`

const HeaderWrapper = styled.header`
  background: #262223;
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0px;
  align-items: center;
  width: 100%;
  height: 50px;
  z-index: 100;
  @media (min-width: 600px) {
    width:600px; 
    margin:auto;
  }
  box-shadow: 0 0px 6px 0 hsla(0, 0%, 0%, 0.2);
`

const NavIcon = styled.img`
  padding: 0px 0px 2px 0px;
  bottom: 0px;
  opacity:${props => props.isPage ?'100%' : '60%' };
  height:${props => props.isPage ?'20px' : '18px' };
  transition: all 0.1s ease-in-out;
`

const UserMenu = ({currentPage, hide, onUserMenuInteraction}) => {
  const {lang} = usePageContext()
  const { t } = useTranslation()
  const isStories  = currentPage === t('urls.home');
  const isFavorites = currentPage === t('urls.favorites');
  const isSearch= currentPage === t('urls.search');
  const isProfile= currentPage === t('urls.profile');
  const isCommunity = currentPage === "/community/";
  const isGuides = currentPage === "/guides/";

  return (
    <>
      {!hide &&
        <HeaderWrapper onClick={onUserMenuInteraction}>
          <NavItemWrapper to={t('urls.home')} >
            <NavIcon isPage={isStories} src={home}></NavIcon>
            <NavLink isPage={isStories}>{t('user-menu.stories')}</NavLink>
          </NavItemWrapper>

          {lang !== "en"  &&
            <NavItemWrapper to={t('urls.favorites')} >
              <NavIcon isPage={isFavorites} src={heart}></NavIcon>
              <NavLink isPage={isFavorites}>{t('user-menu.favorites')}</NavLink>
            </NavItemWrapper>
          }
          {lang === "en"  &&
            <NavItemWrapper to={t('urls.community')} >
              <NavIcon isPage={isCommunity} src={community}></NavIcon>
              <NavLink isPage={isCommunity}>Community</NavLink>
            </NavItemWrapper>
          }
          <NavItemWrapper to={t('urls.search')} >
            <NavIcon isPage={isSearch} src={search}></NavIcon>
            <NavLink isPage={isSearch}>{t('user-menu.search')}</NavLink>
          </NavItemWrapper>
          <NavItemWrapper to={t('urls.guides')} >
            <NavIcon isPage={isGuides} src={signs}></NavIcon>
            <NavLink isPage={isGuides}>{t('user-menu.guides')}</NavLink>
          </NavItemWrapper>

          <NavItemWrapper to={t('urls.sleep')} >
            <NavIcon isPage={isProfile} src={sleep}></NavIcon>
            <NavLink isPage={isProfile}>{t('user-menu.sleep')}</NavLink>
          </NavItemWrapper>
        </HeaderWrapper> 
      }
    </>
  )
}



UserMenu.propTypes = {
  siteTitle: PropTypes.string,
}

UserMenu.defaultProps = {
  siteTitle: ``,
}

export default UserMenu
