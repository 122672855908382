import React, {useContext}from "react";
import styled from 'styled-components'
import AudioPlayerContext from '../audioplayer/AudioPlayerContext';

const ChevronPath = styled.path`
  fill:${props => props.white ?  'white' : 'black'};
` 

const ChevronSvg = styled.svg`
  height:100%;
  width:100%;
`
const  Wrapper = styled.div`
  ${props => props.marginTop ?  'margin-top:30px;' : ''};
  ${props => props.marginLeft ?  'margin-left:30px;' : ''};
  z-index:15;
  ${props => props.absolute ?  'position:absolute' : ''};
  height:30px;
  width:30px;
  @media ${props => props.theme.phoneLand} {
    ${props => props.marginTop ?  'margin-top:25px;' : ''};
    ${props => props.marginLeft ?  'margin-left:25px;' : ''};
    
  }
`


export const BackButton= (props) => {
const {setPlayerMode} = useContext(AudioPlayerContext);

function minimizePlayer() {
  setPlayerMode("small");
}

function handleClick(){
  if(props.closePlayer){
    minimizePlayer();
  } else {
    window.history.back();
  }
}

  return(
    <>
    <Wrapper marginTop={props.marginTop} marginLeft={props.marginLeft} absolute={props.absolute} onClick={handleClick}>
      <ChevronSvg  viewBox="0 0 50 50" width="40px" height="40px">
        <ChevronPath white={props.white}  d="M0.6,24.71L17.32,7.99c0.81-0.81,2.11-0.81,2.92,0l1.95,1.95
      c0.8,0.81,0.8,2.11,0,2.92L8.95,26.17L22.2,39.48c0.8,0.81,0.8,2.11,0,2.92l-1.95,1.95c-0.81,0.81-2.11,0.81-2.92,0L0.6,27.63
      C-0.2,26.82-0.2,25.51,0.6,24.71z"/>
      </ChevronSvg>  
    </Wrapper>  
    </>
  )
}
