import styled from 'styled-components'
import React from "react"
import {Link} from "gatsby";
import Text from '@typo/Text';
import { useTranslation } from 'react-i18next'
import { usePageContext }  from '../../hooks/usePageContext'

const TextCoverSection= styled.section`
  padding:50px 10% 50px 10%;
  display:flex;
  align-items:center;
  @media ${props => props.theme.tabletBelow} {
  flex-direction:column
  }
`

const TextColumn = styled.div`
  width:50%;
  @media ${props => props.theme.tabletBelow} {
    width:100%;
  }
`
const ImageColumn = styled.div`
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  @media ${props => props.theme.tabletBelow} {
    padding-top:50px;
    width:100%;
   }
`

const HeaderTitle = styled.h2`
  ${props => props.topPadding ? 'padding-top: 100px;' : ''}
  font-size: 3rem;
  ${props => props.center ? 'text-align:center;' : ''}
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
    text-align:center;
  }
  @media ${props => props.theme.tabletBelow} {
    width: 90%;
   }
`

const ImageWrapper = styled.div`
  max-width:500px;
`

const CategoryImage = styled.img`
  max-width: 100%;
`

const StoryCoverLink = styled.div`
  font-family: ${props => props.theme.mainFontParagraph};
`

const StyledText = styled(Text)`
  &:first-letter {
    text-transform:${props => props.title ? '' : 'uppercase'};
    font-size:${props => props.title  ? '' : '200%'};
    font-family:${props => props.title ? '' : props.theme.mainFontHeading};
  }
`

export const TextCoverRowNew = (props) => {
  const { lang } = usePageContext()
  const { t } = useTranslation()
  const slug = props.story[lang] && props.story[lang].slug
  const title = props.story[lang] && props.story[lang].title

  return(
    <TextCoverSection>
      <TextColumn>
        <HeaderTitle> {props.title} </HeaderTitle>
        <StyledText dangerouslySetInnerHTML={{ __html: props.text}}/>
      </TextColumn>
      <ImageColumn>
        <Link to={`${t('urls.story')}${slug}/`}>
          <ImageWrapper>
            <CategoryImage src={props.story.imageUrl}/>
          </ImageWrapper>
        </Link>
        <StoryCoverLink>
          <Text>Example Story, <Link to={`${t('urls.story')}${slug}/`}>{title}</Link></Text>
        </StoryCoverLink>
      </ImageColumn>
    </TextCoverSection>
  )
}
