import React from "react"
import styled from 'styled-components'

const SpinnerWrapper= styled.div`
  position:absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 20vh;
  h2{
    display:flex;
    justify-content:center;
  }
`
const Spinner = styled.div`
  margin: 100px auto;
  font-size: 15px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: load5 1.1s infinite ease;
  transform: translateZ(0);
  @-webkit-keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #f19d9a, 1.8em -1.8em 0 0em rgba(241,157,154, 0.2), 2.5em 0em 0 0em rgba(241,157,154, 0.2), 1.75em 1.75em 0 0em rgba(241,157,154, 0.2), 0em 2.5em 0 0em rgba(241,157,154, 0.2), -1.8em 1.8em 0 0em rgba(241,157,154, 0.2), -2.6em 0em 0 0em rgba(241,157,154, 0.5), -1.8em -1.8em 0 0em rgba(241,157,154, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.7), 1.8em -1.8em 0 0em #f19d9a, 2.5em 0em 0 0em rgba(241,157,154, 0.2), 1.75em 1.75em 0 0em rgba(241,157,154, 0.2), 0em 2.5em 0 0em rgba(241,157,154, 0.2), -1.8em 1.8em 0 0em rgba(241,157,154, 0.2), -2.6em 0em 0 0em rgba(241,157,154, 0.2), -1.8em -1.8em 0 0em rgba(241,157,154, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.5), 1.8em -1.8em 0 0em rgba(241,157,154, 0.7), 2.5em 0em 0 0em #f19d9a, 1.75em 1.75em 0 0em rgba(241,157,154, 0.2), 0em 2.5em 0 0em rgba(241,157,154, 0.2), -1.8em 1.8em 0 0em rgba(241,157,154, 0.2), -2.6em 0em 0 0em rgba(241,157,154, 0.2), -1.8em -1.8em 0 0em rgba(241,157,154, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.2), 1.8em -1.8em 0 0em rgba(241,157,154, 0.5), 2.5em 0em 0 0em rgba(241,157,154, 0.7), 1.75em 1.75em 0 0em #f19d9a, 0em 2.5em 0 0em rgba(241,157,154, 0.2), -1.8em 1.8em 0 0em rgba(241,157,154, 0.2), -2.6em 0em 0 0em rgba(241,157,154, 0.2), -1.8em -1.8em 0 0em rgba(241,157,154, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.2), 1.8em -1.8em 0 0em rgba(241,157,154, 0.2), 2.5em 0em 0 0em rgba(241,157,154, 0.5), 1.75em 1.75em 0 0em rgba(241,157,154, 0.7), 0em 2.5em 0 0em #f19d9a, -1.8em 1.8em 0 0em rgba(241,157,154, 0.2), -2.6em 0em 0 0em rgba(241,157,154, 0.2), -1.8em -1.8em 0 0em rgba(241,157,154, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.2), 1.8em -1.8em 0 0em rgba(241,157,154, 0.2), 2.5em 0em 0 0em rgba(241,157,154, 0.2), 1.75em 1.75em 0 0em rgba(241,157,154, 0.5), 0em 2.5em 0 0em rgba(241,157,154, 0.7), -1.8em 1.8em 0 0em #f19d9a, -2.6em 0em 0 0em rgba(241,157,154, 0.2), -1.8em -1.8em 0 0em rgba(241,157,154, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.2), 1.8em -1.8em 0 0em rgba(241,157,154, 0.2), 2.5em 0em 0 0em rgba(241,157,154, 0.2), 1.75em 1.75em 0 0em rgba(241,157,154, 0.2), 0em 2.5em 0 0em rgba(241,157,154, 0.5), -1.8em 1.8em 0 0em rgba(241,157,154, 0.7), -2.6em 0em 0 0em #f19d9a, -1.8em -1.8em 0 0em rgba(241,157,154, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.2), 1.8em -1.8em 0 0em rgba(241,157,154, 0.2), 2.5em 0em 0 0em rgba(241,157,154, 0.2), 1.75em 1.75em 0 0em rgba(241,157,154, 0.2), 0em 2.5em 0 0em rgba(241,157,154, 0.2), -1.8em 1.8em 0 0em rgba(241,157,154, 0.5), -2.6em 0em 0 0em rgba(241,157,154, 0.7), -1.8em -1.8em 0 0em #f19d9a;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #f19d9a, 1.8em -1.8em 0 0em rgba(241,157,154, 0.2), 2.5em 0em 0 0em rgba(241,157,154, 0.2), 1.75em 1.75em 0 0em rgba(241,157,154, 0.2), 0em 2.5em 0 0em rgba(241,157,154, 0.2), -1.8em 1.8em 0 0em rgba(241,157,154, 0.2), -2.6em 0em 0 0em rgba(241,157,154, 0.5), -1.8em -1.8em 0 0em rgba(241,157,154, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.7), 1.8em -1.8em 0 0em #f19d9a, 2.5em 0em 0 0em rgba(241,157,154, 0.2), 1.75em 1.75em 0 0em rgba(241,157,154, 0.2), 0em 2.5em 0 0em rgba(241,157,154, 0.2), -1.8em 1.8em 0 0em rgba(241,157,154, 0.2), -2.6em 0em 0 0em rgba(241,157,154, 0.2), -1.8em -1.8em 0 0em rgba(241,157,154, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.5), 1.8em -1.8em 0 0em rgba(241,157,154, 0.7), 2.5em 0em 0 0em #f19d9a, 1.75em 1.75em 0 0em rgba(241,157,154, 0.2), 0em 2.5em 0 0em rgba(241,157,154, 0.2), -1.8em 1.8em 0 0em rgba(241,157,154, 0.2), -2.6em 0em 0 0em rgba(241,157,154, 0.2), -1.8em -1.8em 0 0em rgba(241,157,154, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.2), 1.8em -1.8em 0 0em rgba(241,157,154, 0.5), 2.5em 0em 0 0em rgba(241,157,154, 0.7), 1.75em 1.75em 0 0em #f19d9a, 0em 2.5em 0 0em rgba(241,157,154, 0.2), -1.8em 1.8em 0 0em rgba(241,157,154, 0.2), -2.6em 0em 0 0em rgba(241,157,154, 0.2), -1.8em -1.8em 0 0em rgba(241,157,154, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.2), 1.8em -1.8em 0 0em rgba(241,157,154, 0.2), 2.5em 0em 0 0em rgba(241,157,154, 0.5), 1.75em 1.75em 0 0em rgba(241,157,154, 0.7), 0em 2.5em 0 0em #f19d9a, -1.8em 1.8em 0 0em rgba(241,157,154, 0.2), -2.6em 0em 0 0em rgba(241,157,154, 0.2), -1.8em -1.8em 0 0em rgba(241,157,154, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.2), 1.8em -1.8em 0 0em rgba(241,157,154, 0.2), 2.5em 0em 0 0em rgba(241,157,154, 0.2), 1.75em 1.75em 0 0em rgba(241,157,154, 0.5), 0em 2.5em 0 0em rgba(241,157,154, 0.7), -1.8em 1.8em 0 0em #f19d9a, -2.6em 0em 0 0em rgba(241,157,154, 0.2), -1.8em -1.8em 0 0em rgba(241,157,154, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.2), 1.8em -1.8em 0 0em rgba(241,157,154, 0.2), 2.5em 0em 0 0em rgba(241,157,154, 0.2), 1.75em 1.75em 0 0em rgba(241,157,154, 0.2), 0em 2.5em 0 0em rgba(241,157,154, 0.5), -1.8em 1.8em 0 0em rgba(241,157,154, 0.7), -2.6em 0em 0 0em #f19d9a, -1.8em -1.8em 0 0em rgba(241,157,154, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(241,157,154, 0.2), 1.8em -1.8em 0 0em rgba(241,157,154, 0.2), 2.5em 0em 0 0em rgba(241,157,154, 0.2), 1.75em 1.75em 0 0em rgba(241,157,154, 0.2), 0em 2.5em 0 0em rgba(241,157,154, 0.2), -1.8em 1.8em 0 0em rgba(241,157,154, 0.5), -2.6em 0em 0 0em rgba(241,157,154, 0.7), -1.8em -1.8em 0 0em #f19d9a;
    }
  }
`


export const LoadingSpinner = ({message}) => { 

  return (
    <SpinnerWrapper>
      <Spinner/>
      <h2>{message}</h2>
    </SpinnerWrapper>
    
  )
}

