import React from "react";
import styled from 'styled-components';
import wave from "../../images/wave-flipped.svg" // Tell Webpack this JS file uses this image

const StoryBadgeMetaBadge = styled.div`
  z-index: 20;
  width: 100%;
  margin-top:-150px;
  height:150px;
`;

const StoryBadgeMetaWrapper = styled.div`
  width: 100%;
  padding-left:5%;  
  bottom: 0px;//to prevent rounding issues
  z-index: 20;
  
  position: absolute;
  background-image: url(${wave});
  background-size: cover;
  padding-top: 100px;
  @media ${props =>props.theme.phonePort} {
    padding-top: 50px;
  }
`;


export const  StoryTitleWave = ({children}) => {
  return (
    <StoryBadgeMetaBadge>
      <StoryBadgeMetaWrapper>
          {children}
      </StoryBadgeMetaWrapper> 
    </StoryBadgeMetaBadge>
  );
}




