const LightTheme = {
  id: 'light',
  primaryColor: 'black',
  secondaryColor: '#F19D9A',
  primaryColorDark:"#D98179",
  backgroundColor: '#FBF6F6',
  darkGrey: "#262223",
  mainFontParagraph: 'Poppins',
  mainFontHeading: 'Romana Becker Demi',
  boxLayoutWidth:'600px',




  boxLayoutQuery:'(min-width: 600px)',
  desktopLarge:'(min-width: 1367px)',
  desktopUp:'(min-width:1100px)',
  desktop:'(min-width:1100px) and (max-width: 1367px)',
  tabletOnly:'(min-width: 980px) and (max-width:1100px)',
  tabletBelow:'(max-width: 980px)',
  tabletUp:'(min-width: 768px)',
  phoneLand:'(max-width: 767px)',
  phonePort:'(max-width: 479px)',
};

export default LightTheme;