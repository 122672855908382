import styled from 'styled-components'
import React from "react"


const ModalWrapper = styled.div`
  top:${props => props.show ? '20%' : '120%' };
  @media (min-width: 600px) {
    top:${props => props.show ? '35%' : '120%' };
  }
  transition: top 0.5s ease-in-out;
  position:fixed;
  /* width:100%;
  margin-left:auto;
  margin-right:auto;
  height:100%; */
  overflow:hidden;
  display:grid;
  place-items: center;
  width: 100%;
  max-width: 600px;
  z-index:999;
`
const ContentWrapper= styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px 0px;
  @media (min-width: 600px) {
    padding:50px 50px;
  }
  padding:10px 10px;
  margin: 10px; /* for box shadow */
`


export const SlideUpModal = ({children, show}) => {
  return(
    <ModalWrapper show={show}>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </ModalWrapper>
  )
}

