import React, {useEffect, useContext} from 'react'
import Modal  from "@common/Modal";
import { LoadingDots } from '../common/LoadingDots';
import { FirebaseContext } from '../Firebase';
import FlashMessage from '@notification/FlashMessage'
import styled from 'styled-components';
import { usePageContext } from '../../hooks/usePageContext';
const SyncInstructionsWrapper = styled.div`
  position:relative ;
  display:grid;
  place-items:center;
  padding: 10px 20px;
  text-align:center;
  >a { 
    background-color:${props => props.theme.secondaryColor};
    padding:10px 40px;
    border-radius:25px;
    text-decoration:none;
    color:white;
    font-family:${props => props.theme.mainFontParagraph};
    text-transform:uppercase;
    font-weight:500;
    margin:20px 0;
  }
  h1{
    font-size:27px;
  }
`;

const QRCode = styled.img`
  height:120px;
  width:120px;
`;

const LovenseButton = styled.div`
  height:35px;
  width: 35px;
  bottom: 130px;
  position: absolute;
  right: 8%;
  z-index:998;
  color:white;
  text-align:center;
  &:before{
    position:absolute;
    padding:3px 5px;
    font-size:8px;
    right:${props => props.status === "connected" ? "-17px;": "-10px;"};
    top:-5px;
    font-family:${props => props.theme.mainFontParagraph};
    content:'${props => props.status === "connected" ? "Unsync": "Sync"}';
    background:${props => props.status === "connected" ? "#F19D9A": "#353535"};
    border-radius:15px;
    text-transform:uppercase;
  }

`;

const LovenseSync = ({lovenseInstance, setLovenseInstance, currentStory, audioPlayerRef, setLovenseData, lovenseData}) => {
  const { user,firebase } = useContext(FirebaseContext);
  const { lang } = usePageContext;

  const handleLovenseClick = async () => {
    try {
      if(user){
        if(lovenseData.status !== "connected"){
          setLovenseData({showModal:true,loading:true})
          audioPlayerRef?.current?.audio?.current.pause() // pause audio 
          const response = await firebase.getLovenseToken()
          const token = response.data.data.token
          const uid = response.data.data.uid
          const durationMs = parseInt(currentStory.duration)*1000 
          setLovenseData({uid,token,showModal:true,loading:true})
          const lovenseInstanceTmp = new window.LovensePattern()
          lovenseInstanceTmp.init({
            uid,
            token,
            pf: "Audiodesires",
            mediaId: currentStory.id,
            duration: durationMs,
          })
          lovenseInstanceTmp.getApp((data) => {
            const { qrCode, appOpenUrl } = data
            setLovenseData({uid,token,showModal:true, qrCode, appOpenUrl,loading:false})
          })       
          setLovenseInstance(lovenseInstanceTmp)
        } else {
          lovenseInstance.exit()
          setLovenseData({status:"disconnected"})
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    //load pattern after connection with remote
    if(lovenseInstance !== ""){
      lovenseInstance.on("remoteConnected", () => {
        setLovenseData({...lovenseData, status:"connected", patternLoaded:false,showModal:false})
        //console.log("remoteConnected Event load pattern")
        lovenseInstance.loadPattern({type: "lovense"})
      })
    }
  },[lovenseInstance,lovenseData])

  useEffect(() => {
    //load pattern after connection with remote
    if(lovenseInstance !== ""){
      lovenseInstance.on("patternLoaded", () => {
        //console.log("patternLoaded Event")
        setLovenseData({...lovenseData, patternLoaded:true})
        // when pattern is loaded set flag so play button will be shown instead of loading 
      })
    }
  },[lovenseInstance,lovenseData])

  useEffect(() => {
    //load pattern after connection with remote
    if(lovenseInstance !== ""){
      lovenseInstance.on("remoteExitPattern", () => {
        console.log("remoteExitPattern")
        setLovenseData({status:"disconnected"})
      })

      lovenseInstance.on("remoteDisconnected", () => {
        //console.log("remoteDisconnected")
        setLovenseData({status:"disconnected"})
      })
    }
  },[lovenseInstance,lovenseData])

  useEffect(() => {
    // when user chose to be use lovense and changes story
    // init again and laod new pattern
    if(lovenseInstance  !== "" && lovenseData.status === "connected"){
      //console.log("changed audio with connected remote, init and load pattern")
      audioPlayerRef?.current?.audio?.current.pause() // pause audio so pattern can load
      const durationMs = parseInt(currentStory.duration)*1000 
      const lovenseMediaId = lang === "en" ? currentStory.id : `${currentStory.id}_${lang}`
      lovenseInstance.init({
        uid:lovenseData.uid,
        token:lovenseData.token,
        pf: "Audiodesires",
        mediaId: lovenseMediaId,
        duration: durationMs,
      })

      lovenseInstance.loadPattern({type: "lovense"})
      
      setLovenseData({...lovenseData, patternLoaded:false})
    }
  }, [currentStory.id])


  return (
      <>
        <LovenseButton status={lovenseData.status} onClick={()=> handleLovenseClick()}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.9 72.9"><circle cx="36.4" cy="36.4" r="32.4" stroke="#f19d9a" stroke-miterlimit="10" fill="none" stroke-width="8"/><path d="M36.5 15.4a20.7 20.7 0 0 0-12.7 4.4 7.3 7.3 0 0 1 2-.3 7 7 0 1 1-7 7 6.9 6.9 0 0 1 0-1.3 20.8 20.8 0 1 0 17.7-9.8Z" fill="#f19d9a" stroke="#f19d9a" stroke-miterlimit="10"/></svg>
        </LovenseButton>
        <Modal show={lovenseData.showModal} hide={()=>setLovenseData({...lovenseData,showModal:false,patternLoaded:true})}>
          <SyncInstructionsWrapper>
            <h1>Sync with Lovense Toy</h1>
            {lovenseData.loading ? 
              <LoadingDots/>
            : 
              <>
                <a target="_blank" href={lovenseData.appOpenUrl}>Sync now</a>
                <p>or scan this code in Lovense Remove app</p>
                <QRCode src={lovenseData.qrCode}/>
              </>
            }
            {lovenseData.status === "connected" && <FlashMessage message={{message:"Successfully connected. Close this popup and start listening to the story." ,type:"success"}}/>}
          </SyncInstructionsWrapper>
        </Modal>
        </>
  )
}

export default LovenseSync