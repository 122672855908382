import React from "react";

const AudioPlayerContext = React.createContext({
  currentStory: 'null',
  setCurrentStory: () => {},
  playerMode: "hidden",
  setPlayerMode: () => {},
});

export default AudioPlayerContext;
