import styled from 'styled-components'
import React from "react"


const ModalWrapper = styled.div`
  top:0;
  visibility: ${props => props.show ? 'visible' : 'hidden' };
  opacity: ${props => props.show ? '1' : '0' };
  transition: opacity 0.5s ease-in-out;
  position:absolute;
  overflow:hidden;
  display:grid;
  place-items: center;
  width: 100%;
  height:100vh;
  max-width: 600px;
  z-index:999;
  
`
const ContentWrapper= styled.div`
  background-color: white;
  position:relative;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px 0px;
  @media (min-width: 600px) {
    padding:50px 50px;
  }
  padding:40px 10px;
  margin: 10px 30px; /* for box shadow */
  z-index:5;
`

const Overlay = styled.div`
  top:0;
  position:absolute;
  width: 100%;
  height:100%;
  background:#5d5d5db5 ;
`;


const CloseButton = styled.div`
  top:20px;
  right:20px;
  position:absolute;
  
`;

 const Modal = ({children, show, hide, hideCloseButton}) => {
  return(
    <ModalWrapper show={show}>
      <ContentWrapper>
        {!hideCloseButton &&
          <CloseButton onClick={()=> hide()}> 
            <svg xmlns="http://www.w3.org/2000/svg" width="29.3" height="29.3"><g data-name="Icon ionic-ios-close-circle-outline"><path data-name="Path 1" d="m20 18.3-3.8-3.7L20 11a1.1 1.1 0 0 0-1.6-1.6L14.6 13 11 9.3A1.1 1.1 0 0 0 9.3 11l3.7 3.7-3.7 3.7a1 1 0 0 0 0 1.6 1.1 1.1 0 0 0 1.6 0l3.7-3.7 3.7 3.7a1.1 1.1 0 0 0 1.6 0 1.1 1.1 0 0 0 0-1.6Z"/><path data-name="Path 2" d="M14.6 2a12.7 12.7 0 1 1-9 3.7 12.6 12.6 0 0 1 9-3.7m0-2a14.6 14.6 0 1 0 14.7 14.6A14.6 14.6 0 0 0 14.6 0Z"/></g></svg>
          </CloseButton>
        }
        {children}
      </ContentWrapper>
      <Overlay onClick={()=>hide()}/>
    </ModalWrapper>
  )
}

export default Modal