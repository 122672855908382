import styled from 'styled-components'
import React, { useContext } from "react";
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { v4 as uuidv4 } from 'uuid'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Virtual } from 'swiper'
import { useTranslation } from 'react-i18next'
import {FirebaseContext} from '../Firebase'
import { usePageContext }  from '@hooks/usePageContext'
import lockOverlay from "@images/lock-overlay.svg" 
import adOg from "@images/logos/ad-original-logo.png" 
import 'swiper/css/bundle'


const StoryTitleSmall = styled.h3`
  margin: 0px 0px;
  font-size:18px;
  font-family: 'Romana Becker Demi';
  display:block;
`;

const StoryMetaSmall = styled.h4`
  font-size:12px;
  margin: 0px 0px;
  text-transform:uppercase;
  font-family: 'Poppins';
  color: #A68C8A;
  font-weight:500;
`;

const SliderTitle = styled.h2`
  font-size:12px;
  font-family: 'Poppins';
  margin: 0px 0px;
  ${props => props.pb && "padding-bottom:20px;"}
`
const SliderSubTitle = styled.h3`
  font-size:25px;
  margin: 0px 0px;
  ${props => props.pb && "padding-bottom:20px;"}
`

const StorySliderLink = styled(Link)`
  text-decoration: none;
  color:#000000;
`

const StorySliderItemMeta = styled.div`
  padding:5% 5% 0% 5%;
  text-align: center;
  margin-bottom: 0px;
`

const OverlayLock = styled.img`
  z-index:15;
  position:absolute;
  top:0px;
  width:100%;
  transform: translate3d(0,0,0) translateZ(1010px); // fixes safari transform bug
`

const StoryRow = styled.div`
  position:relative;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom:30px;
  @media(min-width: 768px){
    padding:0 10%;
  }
  padding:0 5%;
`

const OriginalContentImg = styled.img`
  z-index: 10;
  height: 25px;
  width: 25px;
  top: 15px;
  position: absolute;
  left: 15px;
  transform: translate3d(0,0,0) translateZ(1000px); // fixes safari transform bug
`

const CoverImage = styled(GatsbyImage)`
  z-index:5;
  max-width:300px;
  display: block;
`

const SliderImageWrapper =  styled.div`
  display: block;
  border-radius:5px;
  overflow: hidden;
  transform: translate3d(0,0,0) translateZ(1000px); // fixes safari transform bug
`

const settings = {
  init: false,
  slidesPerView: 1.5,
  spaceBetween: 20,
  freeMode: true,
  breakpoints: {
    600: {
      slidesPerView: 3,
      spaceBetween: 50,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 50,
    },
    1900: {
      slidesPerView: 4,
      spaceBetween: 80,
    }
  }
}

export const StorySlider = (props) => {
  const {lang} = usePageContext()
  const {stories, title, subtitle,  locks,  contentIds, reverseTitle} = props
  const { t } = useTranslation()
  const {user} = useContext(FirebaseContext);
  SwiperCore.use([Virtual]);

  return (
    <StoryRow >
      {reverseTitle ? 
        <>
          <SliderSubTitle user={props.user} dangerouslySetInnerHTML={{ __html: subtitle}}/>
          <SliderTitle pb user={props.user} dangerouslySetInnerHTML={{ __html: title}}/>
        </>
        :
        <>
          <SliderTitle  user={props.user}  dangerouslySetInnerHTML={{ __html: title}}/>
          <SliderSubTitle pb user={props.user}  dangerouslySetInnerHTML={{ __html: subtitle}}/>
        </>
      }
      <Swiper  {...settings}  style={{overflow: 'visible'}}> 
        {contentIds[0] !== "placeholderId" && contentIds.slice(0,10).map((id, index) => (
          <SwiperSlide key={uuidv4()} virtualIndex={index}>  
            <StorySliderLink to={`${t('urls.story')}${stories[id][lang].slug}/`}>
              <SliderImageWrapper>
                {stories[id].audiodesiresOriginal && <OriginalContentImg src ={adOg}></OriginalContentImg>}
                {(((stories[id][lang].premiumContent === "true" && user && !user.isSubscriber) || (stories[id][lang].premiumContent === "true" && !user )) && (locks) ) && 
                  <OverlayLock src={lockOverlay} /> 
                }
                <CoverImage image={stories[id]?.localImage?.childImageSharp.gatsbyImageData}  alt={`${stories[id][lang].title} Erotic Audio Story Audiodesires`}/> 
              </SliderImageWrapper>
              <StorySliderItemMeta>
                <StoryTitleSmall>{stories[id][lang].title}</StoryTitleSmall>
                <StoryMetaSmall>{stories[id][lang].voices[0].name}{`${stories[id][lang].voices[1] ? ` + ${stories[id][lang].voices[1].name}`:""}`}</StoryMetaSmall>
              </StorySliderItemMeta>
            </StorySliderLink>
          </SwiperSlide> 
        ))}  
      </Swiper> 
    </StoryRow>
  );
}
