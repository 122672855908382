import { useEffect, useState } from "react"
import getFirebaseInstance from "./firebase"
import loadFirebaseDependencies from "./loadFirebaseDependencies"

function useAuth() {
  const [user, setUser] = useState(null)
  const [firebase, setFirebase] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
      let unsubscribe
      let publicProfileUnsubscribe;
      loadFirebaseDependencies.then(async app => {
        const firebaseInstance = getFirebaseInstance(app)
        setFirebase(firebaseInstance)
        unsubscribe = await firebaseInstance.auth.onIdTokenChanged(async userResult => {
          if (userResult) {
            const token = await firebaseInstance.auth.currentUser.getIdToken()
            
            setUser({token})
           
          }else{
            if(publicProfileUnsubscribe){
              publicProfileUnsubscribe();
            }
            setLoading(false)
            setUser(null);
          } 
        })
      })
 
      return () => {
        if (unsubscribe) {
            unsubscribe()
        }
      }
  }, [])
  return { user, firebase, loading }
}

export default useAuth