import styled from 'styled-components'
import React from "react"
import {Button} from './';
import {navigate} from "gatsby";
import backgroundWave from "../../images/wave-landing.svg";
import { useTranslation } from 'react-i18next'

const HeaderTitle = styled.h1`

  ${props => props.topPadding ? 'padding-top: 100px;' : ''}
  font-size: 3rem;
  ${props => props.center ? 'text-align:center;' : ''}
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
    text-align:center;
  }
  @media ${props => props.theme.tabletBelow} {
    width: 90%;
   }
`

const CallToActionSection = styled.section`
  padding: 260px 0px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  background-image: url(${backgroundWave});
  background-repeat: no-repeat;
  background-size: contain;
  background-position:center;
  @media ${props => props.theme.phoneLand} {
    padding: 150px 0px;
    background-size: cover;
  }
  @media (min-width: 1900px){
    height:750px;
  }
  @media (min-width: 1930px){
    height:1000px;
  }
  h1{
    max-width:800px;
  }
`

export const  CTAWave = ({title, buttonText}) => {
  const { t } = useTranslation()

  function redirectToRegister(){
    navigate(t('urls.register'))
  }


return (  
<CallToActionSection>
  <HeaderTitle center dangerouslySetInnerHTML={{ __html: title}}/>
    <Button  onClick={redirectToRegister}>{buttonText}</Button>
  </CallToActionSection>
  )

}