import React, {useContext, useRef, useState, useEffect} from "react"
import styled from "styled-components"
import AudioPlayerContext from './AudioPlayerContext';
import { GatsbyImage } from "gatsby-plugin-image";
import { useTranslation } from 'react-i18next'
import Marquee from "react-fast-marquee";

const Wrapper = styled.div`
  transition: all ${props => props.playerMode === 'small' ? "0.6s" :"0.4s"} ease-in-out;
  ${props => props.playerMode === 'small' ? "visibility: visible;" :"visibility: hidden;"}
  ${props => props.playerMode === 'small' ? "opacity: 1;" :"opacity: 0;"}
` 

const InnerWrapper = styled.div`
  position: relative;
  z-index:5; // TODO Seems to overlapp story rating check this out
  height:50px;
  background-color:#F19D9A; 
  width:100%;
  display:flex;
  align-items:center;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 768px) {
    justify-content:start;
  }
`

const ThumbWrapper = styled.div`
  height:50px;
  width:50px;
`

const PlayPauseWrapper = styled.div`
  height:33px;
  width:33px;
  display: inline-flex;
  border-radius:50%;
  justify-content: center;
  align-items: center;
`

const PlayPause =  styled.div`
  box-sizing: border-box;
  height:20px;
  border-color: transparent transparent transparent white;
  /* border-color: yellow blue green white; */
  transition: 100ms all ease;
  will-change: border-width;
  cursor: pointer;
  border-style: solid;
  border-width: 12px 0 12px 20px;
  margin-left: 2px;
  ${props => !props.paused && "border-style: double;"};
  ${props => !props.paused && "border-width:0px 0 0px 20px;"};
  &:focus{
    outline:none !important
  }
`


const ProgressIndicator= styled.div`
  height:5px;
  background-color: #f19d9a63;
  width: 100%;
  z-index:10;
  position:relative;
  &:before{
    content:'';
    position:absolute;
    width:${props => props.completion*100}%;
    height:5px;
    background-color: #fff;
    transition: width 1s ease-in-out;
  }
`




const MiniPlayer = ({audioPlayerRef, currentTime, togglePlay, lovenseInstance}) => {
  const {currentStory, playerMode, setPlayerMode} = useContext(AudioPlayerContext);
  const { t } = useTranslation('components');
  const [overflowingTitle, setOverflowingTitle] = useState(false)
  const ref = useRef(null);
 
  useEffect(() => {
    // check if title overflows for marquee effect
    if (ref?.current?.clientWidth < ref?.current?.scrollWidth) {
      setOverflowingTitle(true);
    } else {
      setOverflowingTitle(false);
    }
  }, [ref,currentStory.storyTitle]);


  const maximizePlayer= () => {
    if((currentStory.id !== "Welcome Audio") && (currentStory.id !== "Welcome Audio Es") && (currentStory.id !== "Welcome Audio De")){
      setPlayerMode("fullscreen");
    }
  }

  const handleCloseClick = () => {
    setPlayerMode("hidden");
    if (!audioPlayerRef.paused) {
      if(lovenseInstance.current){
        lovenseInstance.current.pause()
      } 
      audioPlayerRef.pause()
    }
  }
  return (
    <Wrapper playerMode={playerMode}>
      <InnerWrapper>
        <ThumbWrapper onClick={maximizePlayer}>
          {currentStory.thumbnail &&
            <GatsbyImage image={currentStory.thumbnail} alt={"Story Cover Thumbnail"}/>
          }
        </ThumbWrapper>
        <MetaWrapper>
          <TitleWrapper onClick={maximizePlayer}>
          {currentStory.voiceName !== "placeholder" &&  <h3>{currentStory.voiceName}</h3>}
          {overflowingTitle ?
            <Marquee gradient={true} play={overflowingTitle} gradientWidth={5} gradientColor={[241,157,154]}>
              <StoryTitle ref={ref}>{currentStory.type === "sample" ? `${currentStory.storyTitle} (${t('audio-player-wrapper.sample')}) `: `${currentStory.storyTitle}`}</StoryTitle>
            </Marquee>
          :
            <StoryTitle ref={ref}>{currentStory.storyTitle}{currentStory.type === "sample" && ` (${t('audio-player-wrapper.sample')}) `}</StoryTitle>
          }
          </TitleWrapper>
        </MetaWrapper>
        <ControlsWrapper>
          <CloseButton onClick={handleCloseClick}>
            <svg viewBox="0 0 40 40">
              <path  d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </CloseButton>
          <PlayPauseWrapper onClick={togglePlay}>
            <PlayPause paused={audioPlayerRef?.paused}></PlayPause>
          </PlayPauseWrapper>
        </ControlsWrapper>
      </InnerWrapper>
      <ProgressIndicator completion={currentTime/audioPlayerRef?.duration}/>
    </Wrapper>
  )
}

export default MiniPlayer

const MetaWrapper= styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width: 50%;
  @media (min-width: 768px) {
    padding-left:20px;
  }
` 

const TitleWrapper = styled.div`
  width:100%;
  color:white;
  >h3{
    font-family: 'Poppins';
    font-size:10px;
    margin:0px;
    text-transform:uppercase;
    opacity:0.8;
  }
`

const StoryTitle =  styled.h2`
  white-space:pre;

  font-size:16px;
  margin:0px;
  max-width: 80%;
`

const ControlsWrapper = styled.div`
  width:25%;
  display:flex;
  justify-content: space-around;
  align-items: center;
  @media (min-width: 768px) {
    width:calc(50% - 70px);
    justify-content: end;
    >div{
      margin-left:20px;
    }
  }
`

const CloseButton = styled.div`
  width:35px;
  height:35px;
  >svg{
    width: 100%;
    height: 100%;
    stroke:white;
    stroke-width:2.5;
  }
`